import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {VersioningSpecificationsService} from '../../shared/service/generic/versioning-specifications.service';
import {Rack, RackFilter, RackInput} from './rack';

@Injectable({
	providedIn: 'root'
})
export class RackService extends VersioningSpecificationsService<RackInput, Rack, RackFilter> {

	constructor(public apollo: Apollo) {
		super(apollo, 'Rack');
	}

	findRacksForStateOfArt(siteUuid: string, fields: (keyof Rack | string)[]): Observable<Rack[]> {
		return this.apollo.query({
			query: gql(`
        query findRacksForStateOfArt($siteUuid: String!) {
          findRacksForStateOfArt(siteUuid: $siteUuid) {
          ${fields}
          }
        }`),
			variables: {
				siteUuid
			}
		}).pipe(map(result => result.data[`findRacksForStateOfArt`]));
	}

	createNewVersion(uuid: string, fields: (keyof Rack | string)[]): Observable<Rack> {
		return this.apollo.mutate<Rack>({
			mutation: gql(`
        mutation createRackNewVersion($uuid: String!) {
          createRackNewVersion(uuid: $uuid) {
            ${fields}
          }
        }`),
			variables: {
				uuid
			}
		}).pipe(map(result => result.data[`createRackNewVersion`]));
	}
}
