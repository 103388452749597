<div class="confirm-dialog-container">
	<div mat-dialog-title>
		<h1 [class.header-error]="data.type === 'error'" [class.header-info]="data.type === 'info'"
			[class.header-success]="data.type === 'success'"
			[class.header-warning]="data.type === 'warning'"
			class="title-page">{{data.title | transloco}}</h1>
		<button [mat-dialog-close]="false" class="icon-close"></button>
	</div>

	<div mat-dialog-content>
		<div [innerHTML]="data.useTranslateForContent ? (data.content | transloco) : data.content"
			 style="white-space: pre-wrap"></div>
	</div>
	<div mat-dialog-actions>
		<button [mat-dialog-close]="false" class="link-btn grey mr-3">
			{{data.btnNo | transloco}}
		</button>
		<button *ngIf="data.showYesBtn" [mat-dialog-close]="true" class="color-btn">
			{{data.btnYes | transloco}}
		</button>
	</div>
</div>
