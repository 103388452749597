export abstract class AbstractIdentifier {
	uuid: string;

	constructor(uuid?: string) {
		this.uuid = uuid;
	}
}

export abstract class AbstractIdentifierInput {
}
