import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {HashMap, TranslocoService} from '@ngneat/transloco';
import {combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class TitleService {

	/**
	 * Title service constructor
	 */
	constructor(private _titleService: Title, private _router: Router, private _translator: TranslocoService) {
		combineLatest([this._router.events.pipe(filter(event => event instanceof NavigationEnd)),
			this._translator.events$.pipe(filter(e => e.type === 'translationLoadSuccess'))]).subscribe(() => {
			this.setTitle();
		});
	}

	/**
	 * Set Title with or without data to page title
	 */
	setTitle(data: HashMap = [], titleOverride?: string): void {
		const title: string = titleOverride || this._getTitle(this._router.routerState.root);
		if (title) {
			this._titleService.setTitle(this._translator.translate(title, data));
		} else {
			this._titleService.setTitle('Feelink');
		}
	}

	/**
	 * Get title
	 */
	getTitle(): string {
		return this._titleService.getTitle();
	}

	/**
	 * Récupération du title des datas de la page
	 */
	private _getTitle(root: ActivatedRoute): string {
		let data: string = '';

		if (root.firstChild) {
			data = this._getTitle(root.firstChild);
		} else {
			data = root.snapshot.data.title;
		}

		return data;
	}
}
