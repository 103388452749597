import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
	name: 'calendarStartDate'
})
export class CalendarStartDatePipe implements PipeTransform {

	static defineStartDate(articleWarranty: number, dateSet?: any): moment.Moment {
		if (dateSet) {
			return moment(dateSet);
		}
		let curDate: moment.Moment = moment();
		let warranty: number;
		if (articleWarranty == null || isNaN(articleWarranty)) {
			warranty = 24;
		} else {
			warranty = Number(articleWarranty);
		}
		curDate = curDate.add(warranty, 'months');
		return curDate;
	}

	transform(warranty: number, dateSet?: any): moment.Moment {
		return CalendarStartDatePipe.defineStartDate(warranty, dateSet);
	}
}
