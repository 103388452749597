<div mat-dialog-title>
	<h1 *ngIf="!data.size" class="title-page">{{'rack.dialogAccessory.title' | transloco: {accessory: data.name} }}</h1>
	<h1 *ngIf="data.size" class="title-page">{{'rack.dialogAccessory.edit' | transloco: {accessory: data.name} }}</h1>
	<button [mat-dialog-close]="null" class="icon-close"></button>
</div>
<form [formGroup]="form">
	<div mat-dialog-content>
		<p> {{'rack.dialogAccessory.giveInfos' | transloco}}</p>
		<mat-form-field class="d-block" floatLabel="always">
			<mat-label>
				<span class="align-middle">{{'rack.dialog.name' | transloco}}</span>
			</mat-label>
			<input formControlName="name"
				   matInput
				   required
				   type="text">
			<mat-error *ngIf="form.get('name').hasError('required')">
				{{'form.required' | transloco}}
			</mat-error>
		</mat-form-field>
		<mat-form-field class="d-block" floatLabel="always">
			<mat-label>
				<span class="align-middle">{{'rack.size' | transloco}}</span>
			</mat-label>
			<input formControlName="size"
				   matInput
				   max="200"
				   min="1"
				   required
				   step="1"
				   type="number">
			<mat-error *ngIf="form.get('size').hasError('required')">
				{{'form.required' | transloco}}
			</mat-error>
			<mat-error *ngIf="form.get('size').hasError('min')">
				{{'form.min' | transloco: {min: form.get('size').getError('min').min} }}
			</mat-error>
			<mat-error *ngIf="form.get('size').hasError('max')">
				{{'form.max' | transloco: {max: form.get('size').getError('max').max} }}
			</mat-error>
		</mat-form-field>
	</div>
</form>
<div mat-dialog-actions>
	<button [mat-dialog-close]="null" class="link-btn grey mr-3">
		{{'app.cancel' | transloco}}
	</button>

	<button (click)="save()" class="color-btn">
		{{'app.confirm' | transloco}}
	</button>
</div>
