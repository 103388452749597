import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { firstValueFrom, Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {CalendarStartDatePipe} from '../../shared/pipe/calendar-start-date/calendar-start-date.pipe';
import {
	AbstractIdentifierSpecificationsService
} from '../../shared/service/generic/abstract-identifier-specifications.service';
import {Asset, AssetFilter, AssetInput, AssetInstallationStatus, MultipleAssetInput, Shelf} from './asset';

@Injectable({
	providedIn: 'root'
})
export class AssetService extends AbstractIdentifierSpecificationsService<AssetInput, Asset, AssetFilter> {

	constructor(public apollo: Apollo, private http: HttpClient) {
		super(apollo, 'Asset');
	}

	saveMultiple(
		input: MultipleAssetInput,
		uuid: string,
		version: number,
		type: 'SYNO' | 'RACK',
		fields: (keyof Asset | string)[]): Observable<Asset[]> {
		return this.apollo.mutate<Asset>({
			mutation: gql(`
        mutation saveMultipleAsset($input: MultipleAssetInput!, $uuid: String!, $version: Int!, $type: String!) {
          saveMultipleAsset(input: $input, uuid: $uuid, version: $version, type: $type) {
            ${fields}
          }
        }`),
			variables: {
				input,
				uuid,
				version,
				type
			}
		}).pipe(map(result => result.data[`saveMultipleAsset`]));
	}

	filterAllRest(filter: AssetFilter): Observable<Asset[]> {
		return this.http.post<Asset[]>(`${environment.url}/api/asset`, filter);
	}

	updateMultiple(input: AssetInput[], fields: (keyof Asset | string)[]): Observable<Asset[]> {
		return this.apollo.mutate<Asset>({
			mutation: gql(`
        mutation updateMultipleAsset($input: [AssetInput!]!) {
          updateMultipleAsset(input: $input) {
            ${fields}
          }
        }`),
			variables: {
				input
			}
		}).pipe(map(result => result.data[`updateMultipleAsset`]));
	}

	updateMultipleAssetInSchema(input: AssetInput[]): Observable<void> {
		return this.apollo.mutate<Asset>({
			mutation: gql(`
        mutation updateMultipleAssetInSchema($input: [AssetInput!]!) {
          updateMultipleAssetInSchema(input: $input)
        }`),
			variables: {
				input
			}
		}).pipe(map(result => result.data[`updateMultipleAssetInSchema`]));
	}

	findShelvesForSite(siteUuid: string, preSaleUuid: string, projectUuid: string, fields: (keyof Shelf | string)[]): Observable<Shelf[]> {
		return this.apollo.query({
			query: gql(`
        query findShelvesForSite($siteUuid: String!, $preSaleUuid: String, $projectUuid: String) {
          findShelvesForSite(siteUuid: $siteUuid, preSaleUuid: $preSaleUuid, projectUuid: $projectUuid) {
          	${fields}
          }
        }`),
			variables: {
				siteUuid,
				preSaleUuid,
				projectUuid
			}
		}).pipe(map(result => result.data[`findShelvesForSite`]));
	}

	updateShelvesOfSite(siteUuid: string, preSaleUuid: string, projectUuid: string, input: AssetInput[], fields: (keyof Asset | string)[]): Observable<Asset[]> {
		return this.apollo.mutate<Asset>({
			mutation: gql(`
        mutation updateShelvesOfSite($siteUuid: String!, $preSaleUuid: String, $projectUuid: String, $input: [AssetInput!]!) {
          updateShelvesOfSite(siteUuid: $siteUuid, preSaleUuid: $preSaleUuid, projectUuid: $projectUuid, input: $input) {
            ${fields}
          }
        }`),
			variables: {
				input,
				siteUuid,
				preSaleUuid,
				projectUuid
			}
		}).pipe(map(result => result.data[`updateShelvesOfSite`]));
	}

	findAllLocation(parentUuid?: string, siteUuid?: string): Observable<string[]> {
		return this.apollo.query({
			query: gql(`
        query findAllLocation($parentUuid: String, $siteUuid: String) {
        	findAllLocation(parentUuid: $parentUuid, siteUuid: $siteUuid)
        }`), variables: {
				parentUuid,
				siteUuid
			}
		}).pipe(map(result => result.data[`findAllLocation`]));
	}

	computeAssetStatus(asset: Asset, status: AssetInstallationStatus | null): void {
		asset.status = status;
		switch (status) {
			case AssetInstallationStatus.RECEIVED:
				asset.assetDetails?.forEach(assetDetail => assetDetail.guarantee = null);

				asset.received = true;
				asset.installed = false;
				asset.tested = false;
				break;
			case AssetInstallationStatus.INSTALLED:
				asset.assetDetails
					?.forEach(assetDetail => assetDetail.guarantee = CalendarStartDatePipe.defineStartDate(asset.article?.warranty, assetDetail.guarantee).toDate());

				asset.received = true;
				asset.installed = true;
				asset.tested = false;
				break;
			case AssetInstallationStatus.TESTED:
				asset.assetDetails?.forEach(assetDetail => {
					if (!assetDetail.guarantee) {
						assetDetail.guarantee = CalendarStartDatePipe.defineStartDate(asset.article?.warranty).toDate();
					}
				});

				asset.received = true;
				asset.installed = true;
				asset.tested = true;
				break;
			default:
				asset.received = false;
				asset.installed = false;
				asset.tested = false;

				asset.assetDetails?.forEach(assetDetail => assetDetail.guarantee = null);
				break;
		}
	}
}
