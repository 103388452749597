import {Expose} from 'class-transformer';
import {AbstractVersionFilter} from 'src/app/shared/dto/generic/abstract-filter';
import {VerisonedInput, Versioned} from 'src/app/shared/dto/generic/versioned';
import {GenericTriStateEnum} from '../../shared/enum/generic/state.enum';
import {Asset} from '../asset/asset';
import {Site} from '../customer/site/site';
import {PreSale} from '../project/pre-sale/pre-sale';
import {Project} from '../project/project/project';

export class Rack extends Versioned {
	site: Site;
	project: Project;
	preSale: PreSale;
	assets: Asset[];
	name: string;
	size: number;
	depth: number;
	bothSide: boolean;
	color: string;
	structure: string;
	frontDoor: string;
	backDoor: string;
	frontLock: boolean;
	backLock: boolean;
	feet: string;
	side: string;
	json: string | object;
}

export class RackInput extends VerisonedInput {
	@Expose() uuid: string;
	@Expose() version: number;
	@Expose() preSaleUuid: string;
	@Expose() projectUuid: string;
	@Expose() siteUuid: string;
	@Expose() name: string;
	@Expose() state: GenericTriStateEnum;
	@Expose() size: number;
	@Expose() depth: number;
	@Expose() bothSide: boolean;
	@Expose() color: string;
	@Expose() structure: string;
	@Expose() frontDoor: string;
	@Expose() backDoor: string;
	@Expose() frontLock: boolean;
	@Expose() backLock: boolean;
	@Expose() feet: string;
	@Expose() side: string;
	@Expose() json: string;
}

export class RackFilter extends AbstractVersionFilter {
	siteUuid?: string;
	sites?: string[];
	state?: GenericTriStateEnum;
	projectUuid?: string;
	projectRef?: string;
	notInProject?: boolean;
}
