import {HttpClient} from '@angular/common/http';
import {Injectable, InjectionToken, NgModule} from '@angular/core';

@Injectable({providedIn: 'root'})
export class EnvironmentHttpLoader {
	constructor(private http: HttpClient) {
	}

	getConfig(): Promise<any> {
		return this.http.get(`/assets/config/environments.json`).toPromise();
	}
}

export const ENVIRONMENT_LOADER: InjectionToken<any> = new InjectionToken<any>('ENVIRONMENT_LOADER');

@NgModule({
	providers: [
		{provide: ENVIRONMENT_LOADER, useClass: EnvironmentHttpLoader}
	]
})
export class EnvironmentRootModule {
}
