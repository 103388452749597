import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SharedService {
	public version: string = environment.version;
	private _title: string = 'Feelink-web';
	private _loaderVisible: boolean = false;

	constructor() {
	}

	get title(): string {
		return this._title;
	}

	set title(value: string) {
		this._title = value;
	}

	get loaderVisible(): boolean {
		return this._loaderVisible;
	}

	showLoader(): void {
		this._loaderVisible = true;
	}

	hideLoader(): void {
		this._loaderVisible = false;
	}
}
