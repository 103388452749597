<div class="menu">
	<div class="logo-container">
		<a href="javascript:void(0)" (click)="goToMain()">
<!--			<div class="icon-deya"></div>-->
			<img src="/assets/images/feelink/feelink_carre_fond degrade.png" alt="Feelink logo">
		</a>
	</div>

	<div class="menu-content">
		<div *ngFor="let menu of menuService.menus" [class.active]="menu.active" class="menu-entry">
			<a [routerLink]="menu.routes[0]">
				<div class="menu-entry-icon">
					<span class="icon-{{menu.icon}}"></span>
				</div>
				<div class="small-label">
					<span>{{menu.label | transloco}}</span>
				</div>
			</a>
		</div>
	</div>

	<div class="bottom">
		<button #menuLauncher="matButton" class="power-btn" [matMenuTriggerFor]="menu" mat-button>
			<span class="icon-power"></span>
		</button>
	</div>
	<mat-menu #menu="matMenu">
		<button disabled mat-menu-item>Version web : {{version}}</button>
		<button disabled mat-menu-item>Version serveur : {{serverVersion}}</button>
		<button disabled mat-menu-item>Profils : <span *ngFor="let company of visibleCompanies; let last = last">{{('company.' + company) | transloco}}{{last ? '' : ' - '}}</span></button>
		<button (click)="logout()" mat-menu-item>{{'app.logout' | transloco}}</button>
	</mat-menu>
</div>
