import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpParameterCodec,
	HttpParams,
	HttpRequest
} from '@angular/common/http';
import {Injectable, Provider} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {SharedService} from '../shared/shared.service';
import {SnackbarService} from '../snackbar/snackbar.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorInterceptor extends KeycloakBearerInterceptor {

	constructor(private _sharedService: SharedService,
				private _snackbarService: SnackbarService,
				private _keycloakService: KeycloakService,
				private _router: Router) {
		super(_keycloakService);
	}

	public static onError(
		err: HttpErrorResponse,
		message: string,
		url: string,
		sharedService: SharedService,
		snackbarService: SnackbarService,
		keycloakService: KeycloakService): void {
		sharedService.hideLoader();

		if (url.startsWith('/assets/')) {
			// Affiche l’erreur dans une snackbar pendant 30sec
			snackbarService.showError('Please check translation file !', {
				config: {
					duration: 0
				},
				action: 'app.close'
			});
		} else if (message) {
			// Affiche l’erreur dans une snackbar pendant 30sec
			snackbarService.showError(message, {
				config: {
					duration: 0,
				},
				action: 'app.close'
			});
		}

		if (err) {
			console.error(err.status, err, url, message);
			if (err.status === 401 && !window.location.href.includes('/login')) {
				// auto logout if 401 response returned from api
				// logout
				keycloakService.logout();
				window.location.reload();
			}

			// On error 403, go to 403
			if (err.status === 403) {
				snackbarService.showWarning('error.403');
				// Logout
				// keycloakService.logout();
				// window.location.reload();
			}

			// On error 0 (no internet connection)
			if (err.status === 0) {
				return;
			}

			// On error 500
			if (err.status === 500) {
				// return;
			}
		}
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (!request.url.startsWith('/assets/')) {
			if (!request.url.includes(environment.keycloak.url)) {
				const params: HttpParams = new HttpParams({encoder: new CustomEncoder(), fromString: request.params.toString()});
				request = request.clone({
					params,
					headers: request.headers
						.append('Application', 'PANDORA-WEB')
				});
			}
		}

		return super.intercept(request, next).pipe(
			catchError((err: HttpErrorResponse) => {
				const error: string = err.error.error || err.error.message || err.statusText;
				const urlTree: UrlTree = this._router.parseUrl(request.url.replace(environment.url, ''));
				if (urlTree.queryParamMap.has('ignoreError')) {
					return throwError(err);
				} else {
					console.error(error);
				}
				ErrorInterceptor.onError(err, '[Erreur WS] ' + error, request.url, this._sharedService, this._snackbarService, this._keycloakService);
				return throwError(err);
			})
		);
	}
}

class CustomEncoder implements HttpParameterCodec {
	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}

	decodeKey(key: string): string {
		return decodeURIComponent(key);
	}

	decodeValue(value: string): string {
		return decodeURIComponent(value);
	}
}

export const errorInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: ErrorInterceptor,
	multi: true
};
