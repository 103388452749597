import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Site } from 'src/app/objects/customer/site/site';
import { CustomerService } from '../../../objects/customer/customer.service';
import { Rack, RackInput } from '../../../objects/rack/rack';
import { RackService } from '../../../objects/rack/rack.service';

@Component({
	selector: 'app-rack-add-dialog',
	templateUrl: './rack-add-dialog.component.html',
	styleUrls: ['./rack-add-dialog.component.scss']
})
export class RackAddDialogComponent {

	form: UntypedFormGroup;
	rack: RackInput;

	displayedColumnsRack: string[] = ['name', 'action'];
	racksOfSite: Rack[] = [];

	constructor(private _fb: UntypedFormBuilder,
				private _rackService: RackService,
				private _dialogRef: MatDialogRef<RackAddDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		this.rack = new RackInput();
		this.form = this._fb.group({
			site: new UntypedFormControl(null, [Validators.required]),
			name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
			size: new UntypedFormControl(1, [Validators.required, Validators.min(1), Validators.max(200)])
		});
		if (data.rackSize) {
			this.form.get('size').patchValue(data.rackSize);
		}
		if (data.siteUuid) {
			const site: Site = this.data.sites.find(value => value.uuid === data.siteUuid);
			this.form.get('site').patchValue(site);
			this.form.get('site').disable();
			this.getRacksOfSite();
		}
	}

	getRacksOfSite(): void {
		// On va récupérer les racks du site, qui ne sont pas encore dans le projet
		if (this.data.project) {
			this._rackService.filterAll({
				siteUuid: this.form.get('site').value.uuid,
				notInProject: true
			}, ['uuid', 'version', 'name']).toPromise().then(res => {
				if (res) {
					this.racksOfSite = res;
				}
			});
		}
	}

	addRack(rack: Rack): void {
		this._dialogRef.close({rack, create: false});
	}

	save(): void {
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}
		this.rack.siteUuid = this.form.get('site').value.uuid;
		this.rack.name = this.form.get('name').value;
		this.rack.size = Math.trunc(this.form.get('size').value);

		this._dialogRef.close({rack: this.rack, create: true, site: this.form.get('site').value});
	}
}
