import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs/internal/Observable';
import {Setting, SettingInput} from '../../dto/generic/setting';
import {SettingKey} from '../../enum/generic/setting-key.enum';
import {AbstractIdentifierService} from './abstract-identifier.service';

@Injectable({
	providedIn: 'root'
})
export class SettingService extends AbstractIdentifierService<SettingInput, Setting> {

	settings: Setting[] = [];

	constructor(protected apollo: Apollo) {
		super(apollo, 'Setting');
	}

	findByKey(key: SettingKey): string {
		return this.settings.find(value => value.key === key)?.value;
	}

	update(uuid: string, input: SettingInput, fields: (keyof Setting | string)[]): Observable<Setting> {
		const setting: Setting = this.settings.find(value => value.uuid === uuid);
		input.value = input.value.toString();
		if (setting) {
			setting.value = input.value;
		}
		return super.update(uuid, input, fields);
	}
}
