import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ApolloClientOptions, ApolloLink, HttpLink, InMemoryCache, split} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {GraphQLRequest} from '@apollo/client/link/core';
import {onError} from '@apollo/client/link/error';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {ApolloModule} from 'apollo-angular';
import {FragmentDefinitionNode, OperationDefinitionNode} from 'graphql';
import {KeycloakService} from 'keycloak-angular';
import {throwError} from 'rxjs';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {environment} from '../environments/environment';
import {ErrorInterceptor} from './shared/service/interceptor/error.interceptor';
import {SharedService} from './shared/service/shared/shared.service';
import {SnackbarService} from './shared/service/snackbar/snackbar.service';

export async function createApolloLink(
	sharedService: SharedService,
	keycloakService: KeycloakService,
	snackbarService: SnackbarService): Promise<ApolloLink> {
	const uri: string = environment.url + '/graphql'; // <-- add the URL of the GraphQL server here

	const httpLink: ApolloLink = new HttpLink({
		uri
	});

	const wsClient: SubscriptionClient = new SubscriptionClient(uri.replace('http', 'ws'),
		{
			reconnect: true,
			lazy: true,
			connectionParams: () => keycloakService.getToken().then(token => {
				return {token};
			}),
			connectionCallback: (error, result) => {
				console.log(error, result);
				if (error?.length) {
					console.error('ws error', error);
				}
				if (result) {
					console.info(result);
				}
			}
		});

	const wsLink: WebSocketLink = new WebSocketLink(wsClient);
	wsClient.onConnecting((...args) => {
		console.log('connecting', args);
	});
	wsClient.onReconnecting((...args) => {
		console.log('reconnecting', args, this, wsClient);
		(wsClient as any).tryReconnect();
	});
	wsClient.onDisconnected((...args) => {
		console.log('disconnected', args);
	});

	// The split function takes three parameters:
	//
	// * A function that's called for each operation to execute
	// * The Link to use for an operation if the function returns a "truthy" value
	// * The Link to use for an operation if the function returns a "falsy" value
	const splitLink: ApolloLink = split(
		({ query }) => {
			const definition: OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(query);
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			);
		},
		wsLink,
		httpLink
	);

	const context: ApolloLink = setContext(async (operation: GraphQLRequest, prevContext: any) => {
		await keycloakService.updateToken();
		return {
			headers: {
				Authorization: 'Bearer ' + await keycloakService.getToken(),
				Application: 'PANDORA-WEB'
			}
		};
	});

	const errorLink: ApolloLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.forEach(({ extensions, message, locations, path }) => {
				// const error: string = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(path)}`;
				// console.error(error);
				ErrorInterceptor.onError(extensions?.exception as any, null, path[0].toString(), sharedService, snackbarService, keycloakService);
				throwError(extensions?.exception);
			});
		}

		if (networkError) {
			console.error(`[Network error]: ${JSON.stringify(networkError)}`);
		}
	});

	return ApolloLink.from([errorLink, context, splitLink]);
}

export async function createApollo(
	sharedService: SharedService,
	keycloakService: KeycloakService,
	snackbarService: SnackbarService): Promise<ApolloClientOptions<any>> {
	return {
		link: await createApolloLink(sharedService, keycloakService, snackbarService),
		cache: new InMemoryCache({
			dataIdFromObject: object => (object as any).uuid || null
		}),
		defaultOptions: {
			watchQuery: {
				fetchPolicy: 'no-cache'
			},
			query: {
				fetchPolicy: 'no-cache'
			},
			mutate: {
				fetchPolicy: 'no-cache'
			},
		}
	};
}

@NgModule({
	imports: [HttpClientModule, ApolloModule]
})
export class GraphQLModule {
}
