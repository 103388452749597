/**
 * Generic tri state enum for ACTIVE, INACTIVE, DELETED state types
 */
export enum GenericTriStateEnum {

	/**
	 * By default : Active
	 */
	ACTIVE = 'ACTIVE',

	/**
	 * INACTIVE
	 */
	INACTIVE = 'INACTIVE',

	/**
	 * Deleted state
	 */
	DELETED = 'DELETED'
}

export enum GenericBiStateEnum {

	ACTIVE = 'ACTIVE',

	DELETED = 'DELETED'
}
