export declare type Menus = Menu[];

/**
 * Menu
 */
export class Menu {
	/**
	 * Translate key for the menu
	 */
	label: string;
	/**
	 * Icon to show
	 */
	icon?: string;
	/**
	 * Route
	 */
	routes: string[];
	/**
	 * Position of the menu compared to others
	 */
	position: number;
	/**
	 * active class on menu if we want to
	 */
	active?: boolean = false;
	/**
	 * Is default route
	 */
	isDefault?: boolean = false;
}
