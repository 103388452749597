import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {firstValueFrom, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {FileDto} from '../../dto/generic/file.dto';

export type FileFolderType =
	'article'
	| 'customer'
	| 'photo'
	| 'pre-sale'
	| 'project'
	| 'DOE'
	| 'articles-csv'
	| 'tech-memoir';

@Injectable({
	providedIn: 'root'
})
export class FileManagementService {

	cachedImage: Map<string, string> = new Map<string, string>();

	protected constructor(public http: HttpClient, private _router: Router, private _sanitizer: DomSanitizer) {
	}

	static saveFile(blob: Blob, filename: string): void {
		const aElem: any = document.createElement('a');
		aElem.href = URL.createObjectURL(blob);
		aElem.download = filename;
		// start download
		aElem.click();
	}

	getFileLink(type: string, filename: string): string {
		return environment.url + '/api/file/' + type + '/' + filename;
	}

	getDownloadLink(type: string, filename: string, width?: number, height?: number): string {
		const tree: any = this._router.createUrlTree(['api', 'download', type, filename], {
			queryParams: {
				width,
				height,
				ignoreError: true
			}
		});
		return this._router.serializeUrl(tree);
	}

	downloadFile(type: FileFolderType, filename: string, width?: number, height?: number): Promise<void> {
		const url: string = this.getDownloadLink(type, filename, width, height);
		return firstValueFrom(this.http.get(environment.url + url, {responseType: 'blob'})).then(value => {
			FileManagementService.saveFile(value, filename);
		}).catch(err => {
			console.warn('No file found for ' + filename);
		});
	}

	async getFileBlob(type: FileFolderType, filename: string, width?: number, height?: number): Promise<string> {
		let objectUrl: string;
		const url: string = this.getDownloadLink(type, filename, width, height);
		if (this.cachedImage.has(url)) {
			objectUrl = this.cachedImage.get(url);
		} else {
			const fileBlob: Blob = await firstValueFrom(this.http.get(environment.url + url, {
				responseType: 'blob'
			})).catch(err => {
				console.warn('No file found for ' + filename);
				this.cachedImage.set(url, objectUrl);
				return null;
			});
			if (fileBlob) {
				objectUrl = URL.createObjectURL(fileBlob);
				this.cachedImage.set(url, objectUrl);
			}
		}
		return objectUrl;
	}

	getFileBlobObs(type: FileFolderType, filename: string, width?: number, height?: number): Observable<SafeUrl> {
		let objectUrl: string;
		const url: string = this.getDownloadLink(type, filename, width, height);
		if (this.cachedImage.has(url)) {
			return of(this.cachedImage.get(url) ? this._sanitizer.bypassSecurityTrustUrl(this.cachedImage.get(url)) : null);
		} else {
			return this.http.get(environment.url + url, {
				responseType: 'blob'
			}).pipe(
				map(blob => {
					objectUrl = URL.createObjectURL(blob);
					this.cachedImage.set(url, objectUrl);
					return this._sanitizer.bypassSecurityTrustUrl(objectUrl);
				}),
				catchError(err => {
					console.warn('No file found for ' + filename);
					this.cachedImage.set(url, null);
					return '';
				})
			);
		}
	}

	async getBlobFromBase64(base64: string): Promise<Blob> {
		return firstValueFrom(this.http.get(base64, {responseType: 'blob'})).catch(err => {
			console.warn('No file found');
			return null;
		});
	}

	uploadFile(file: File | Blob, type: string, resize?: boolean, uniqueName?: boolean): Promise<FileDto> {
		const formdata: FormData = new FormData();
		formdata.append('file', file);
		const url: string = environment.url + '/api/upload/' + type;
		return firstValueFrom(this.http.post(url, formdata, {
			params: {
				resize: (resize?.toString()),
				uniqueName: (uniqueName?.toString())
			}
		}))
			.then(result => {
				return result;
			})
			.catch(exception => {
				console.error(exception);
				return null;
			});
	}

	uploadMultipleFile(files: (File | Blob)[], type: string, resize: boolean = false, uniqueName: boolean = false): Promise<FileDto[]> {
		const formdata: FormData = new FormData();
		for (const file of files) {
			formdata.append('files', file);
		}
		const url: string = environment.url + '/api/upload-multiple/' + type;
		return firstValueFrom(this.http.post(url, formdata, {
			params: {
				resize,
				uniqueName
			}
		}))
			.then(result => {
				return result;
			})
			.catch(exception => {
				console.error(exception);
				return null;
			});
	}
}
