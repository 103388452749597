export enum SettingKey {

	LINK_CATEGORY_STRING = 'LINK_CATEGORY_STRING',

	LINK_LENGTH_FIELD_STRING = 'LINK_LENGTH_FIELD_STRING',

	ARTICLE_POINTS_CREATE_NUMBER = 'ARTICLE_POINTS_CREATE_NUMBER',

	ARTICLE_POINTS_VERIFICATION_NUMBER = 'ARTICLE_POINTS_VERIFICATION_NUMBER',

	ARTICLE_POINTS_UPDATE_NUMBER = 'ARTICLE_POINTS_UPDATE_NUMBER',

	RACK_AVERAGE_U_COST_NUMBER = 'RACK_AVERAGE_U_COST_NUMBER',

	TECH_MEMOIR_TEMPLATE_PATH_STRING = 'TECH_MEMOIR_TEMPLATE_PATH_STRING',

	CONTROLLER_CATEGORY_STRING = 'CONTROLLER_CATEGORY_STRING',

	PROJECT_NAME_STRING = 'PROJECT_NAME_STRING',

	SYNO_AUTOSAVE_INTERVAL_NUMBER = 'SYNO_AUTOSAVE_INTERVAL_NUMBER',

	DEYA_PROJECT_SIGNATURE_HTML = 'DEYA_PROJECT_SIGNATURE_HTML',

	VIDEOLINE_PROJECT_SIGNATURE_HTML = 'VIDEOLINE_PROJECT_SIGNATURE_HTML',

	DEYA_LOGO_IMAGE = 'DEYA_LOGO_IMAGE',

	VIDEOLINE_LOGO_IMAGE = 'VIDEOLINE_LOGO_IMAGE',
}
