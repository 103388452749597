import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-header-bar',
	templateUrl: './header-bar.component.html',
	styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent {

	@Input() btnPaddingRight: number = 20;

	constructor() {
	}

}
