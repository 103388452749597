<div class="menu-container">
	<app-menu></app-menu>
</div>
<div class="content-container">
	<router-outlet></router-outlet>
</div>

<div *ngIf="sharedService.loaderVisible" class="loader-container">
	<div class="loader">
		<mat-spinner></mat-spinner>
	</div>
</div>
