import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {SharedModule} from '../shared/shared.module';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {MenuComponent} from './menu/menu/menu.component';
import {SynopticLayoutComponent} from './synoptic-layout/synoptic-layout.component';

@NgModule({
	declarations: [MainLayoutComponent, MenuComponent, SynopticLayoutComponent],
	exports: [
		MenuComponent
	],
	imports: [
		SharedModule,
		MatMenuModule,
		MatButtonModule,
		MatOptionModule
	]
})
export class LayoutModule {
}
