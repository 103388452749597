import {HttpClient} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {
	provideTransloco,
	Translation,
	TRANSLOCO_CONFIG,
	TRANSLOCO_LOADER,
	translocoConfig,
	TranslocoLoader,
	TranslocoModule
} from '@ngneat/transloco';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private http: HttpClient) {
	}

	getTranslation(lang: string): Observable<Translation> {
		return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
	}
}

@NgModule({
	exports: [TranslocoModule],
	providers: [
		provideTransloco({
			config: {
				availableLangs: ['fr'],
				defaultLang: 'fr',
				fallbackLang: 'fr',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: environment.production,
			},
			loader: TranslocoHttpLoader
		})
	]
})
export class TranslocoRootModule {
}
