import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
	MatMomentDateModule,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MomentDateAdapter
} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {QuillModule} from 'ngx-quill';
import {BackButtonComponent} from './component/back-button/back-button.component';
import {CardComponent} from './component/card/card.component';
import {CheckedListItemComponent} from './component/checked-list-item/checked-list-item.component';
import {HeaderBarComponent} from './component/header-bar/header-bar.component';
import {InputDialogComponent} from './component/input-dialog/input-dialog.component';
import {LeftMenuComponent} from './component/left-menu/left-menu.component';
import {PaginatorComponent} from './component/paginator/paginator.component';
import {SearchComponent} from './component/search/search.component';
import {SelectAutocompleteComponent} from './component/select-autocomplete/select-autocomplete.component';
import {StatusIndicatorComponent} from './component/status-indicator/status-indicator.component';
import {FocusedHoveredDirective} from './directive/focused-hovered.directive';
import {CalendarStartDatePipe} from './pipe/calendar-start-date/calendar-start-date.pipe';
import { FindPipe } from './pipe/find.pipe';
import {FormArrayPipe} from './pipe/form-array/form-array.pipe';
import {FormControlPipe} from './pipe/form-array/form-control.pipe';
import {FormGroupPipe} from './pipe/form-array/form-group.pipe';
import {GetSelectedItemsPipe} from './pipe/get-selected-items/get-selected-items.pipe';
import {IsInArrayPipe} from './pipe/is-in-array/is-in-array.pipe';
import {MultiplyPipe} from './pipe/multiply/multiply.pipe';
import {NgForNumberPipe} from './pipe/ng-for-number/ng-for-number.pipe';
import {OuterHeightPipe} from './pipe/outer-height/outer-height.pipe';
import {RoundPipe} from './pipe/round/round.pipe';
import {SafizerPipe} from './pipe/safizer/safizer.pipe';
import {SupprTimestampPipe} from './pipe/suppr-timestamp/suppr-timestamp.pipe';
import {TimerFormatterPipe} from './pipe/timer-formatter/timer-formatter.pipe';

@NgModule({
	declarations: [
		SafizerPipe,
		TimerFormatterPipe,
		SearchComponent,
		StatusIndicatorComponent,
		PaginatorComponent,
		CheckedListItemComponent,
		LeftMenuComponent,
		HeaderBarComponent,
		CardComponent,
		BackButtonComponent,
		FormArrayPipe,
		FormControlPipe,
		FormGroupPipe,
		NgForNumberPipe,
		FocusedHoveredDirective,
		OuterHeightPipe,
		MultiplyPipe,
		RoundPipe,
		SupprTimestampPipe,
		CalendarStartDatePipe,
		InputDialogComponent,
		SelectAutocompleteComponent,
		IsInArrayPipe,
		GetSelectedItemsPipe,
		FindPipe
	],
	imports: [
		CommonModule,
		TranslocoModule,
		MatTooltipModule,
		MatInputModule,
		MatSelectModule,
		MatCheckboxModule,
		FormsModule,
		MatDialogModule,
		QuillModule.forRoot(),
		MatIconModule,
		FormsModule,
		ReactiveFormsModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatNativeDateModule,
		MatDatepickerModule
	],
	exports: [
		CommonModule,
		HttpClientModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		SafizerPipe,
		TimerFormatterPipe,
		OuterHeightPipe,
		FormArrayPipe,
		FormControlPipe,
		FormGroupPipe,
		TranslocoModule,
		MatInputModule,
		MatSnackBarModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		SearchComponent,
		StatusIndicatorComponent,
		PaginatorComponent,
		CheckedListItemComponent,
		LeftMenuComponent,
		HeaderBarComponent,
		CardComponent,
		BackButtonComponent,
		NgForNumberPipe,
		FocusedHoveredDirective,
		MultiplyPipe,
		RoundPipe,
		SupprTimestampPipe,
		CalendarStartDatePipe,
		SelectAutocompleteComponent,
		IsInArrayPipe,
		GetSelectedItemsPipe,
		FindPipe
	],
	providers: [
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {
				duration: 3000
			}
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				panelClass: 'app-dialog',
				maxWidth: '80%',
				maxHeight: '80%',
				hasBackdrop: true,
				disableClose: true,
				minWidth: '30%',
				data: null
			}
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: 'fr-FR'
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: ['L'],
				},
				display: {
					dateInput: 'L',
					monthYearLabel: 'MMM YYYY',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM YYYY',
				},
			},
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: {useUtc: true}
		}
	]
})
export class SharedModule {
}
