import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

const AUTOCOMPLETE_FIELDS: string[] = ['categories {uuid, name}', 'fields {field, type, values, categories {uuid, name, parentField}}', 'operators', 'links', 'operatorsByValueType {valueType, operators}'];

export type queryType =
	'caracteristic'
	| 'operator'
	| 'value'
	| 'link';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
	@Input() value: string;

	@Input() disabled: boolean = false;

	@Input() clearBtn: boolean = false;

	@Input() inputTimeout: number = 500;

	@Output() searchEvent: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

	keyupTimeout: any;

	clearInput(): void {
		this.searchInput.nativeElement.value = '';
	}

	search(input: HTMLInputElement): void {
		clearTimeout(this.keyupTimeout);
		this.keyupTimeout = setTimeout(() => {
			this.searchEvent.emit(input.value);
		}, this.inputTimeout ?? 200);
	}
}
