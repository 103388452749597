<mat-form-field>
	<span class="icon-search"></span>
	<input #searchInput
		   (keyup)="search(searchInput)"
		   (keyup.enter)="search(searchInput)"
		   [disabled]="disabled"
		   [value]="value || ''"
		   [placeholder]="'app.search' | transloco"
		   autocomplete="off"
		   matInput
		   type="search">

	<button (click)="$event.stopPropagation(); searchInput.value = null; search(searchInput)" *ngIf="clearBtn"
			[disabled]="!searchInput?.value" [matTooltip]="'app.clear' | transloco" class="btn-icon red"
			matSuffix
			type="button">
		<span class="icon-close"></span>
	</button>
</mat-form-field>
