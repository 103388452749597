import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Utils} from '../../utils/utils';

export abstract class BaseService<T> {

	constructor(protected apollo: Apollo, protected className: string) {
	}

	findAll(fields: (keyof T | string)[]): Observable<T[]> {
		return this.apollo.query({
			query: gql(`
        query ${Utils.lowerFirstChar(this.className)}s {
          ${Utils.lowerFirstChar(this.className)}s {
            ${fields}
            }
        }`)
		}).pipe(map(result => result.data[`${Utils.lowerFirstChar(this.className)}s`]));
	}
}
