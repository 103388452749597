import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {plainToClass} from 'class-transformer';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile} from 'keycloak-js';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {Group, User} from '../../../objects/user/user';
import {UserRolesEnum} from '../../../objects/user/user-roles.enum';
import {Paginated} from '../../dto/generic/paginated';
import {Pagination} from '../../dto/generic/pagination';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(public http: HttpClient,
				public apollo: Apollo,
				public keycloakService: KeycloakService) {
	}

	findAllPaginated(pagination: Pagination): Observable<Paginated<User>> {
		return this.apollo.query({
			query: gql(`
        query keycloakUsersPage($sortColumn: String, $sortOrder: SortOrder, $first: Int, $after: String, $offset: Int){
          keycloakUsersPage(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after, offset: $offset) {
          	totalCount,
          	skip,
          	take,
          	pageInfo {
          	  hasPreviousPage,
          	  hasNextPage,
          	  startCursor,
          	  endCursor,
          	  page
          	},
          	nodes {
          		id,
				groups,
				createdTimestamp,
				username,
				enabled,
				emailVerified,
				firstName,
				lastName,
				email,
				attributes { company }
          	}
          }
        }`), variables: pagination
		}).pipe(map(result => result.data[`keycloakUsersPage`]));
	}

	findAllPaginatedByName(pagination: Pagination, search: string): Observable<Paginated<User>> {
		return this.apollo.query({
			query: gql(`
        query keycloakUsersByNamePage($sortColumn: String, $sortOrder: SortOrder, $first: Int, $after: String, $offset: Int, $search: String!){
          keycloakUsersByNamePage(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after, offset: $offset, search: $search) {
          	totalCount,
          	skip,
          	take,
          	pageInfo {
          	  hasPreviousPage,
          	  hasNextPage,
          	  startCursor,
          	  endCursor,
          	  page
          	},
          	nodes {
          		id,
				groups,
				createdTimestamp,
				username,
				enabled,
				emailVerified,
				firstName,
				lastName,
				email,
				attributes { company }
          	}
          }
        }`), variables: Object.assign(pagination, {
				search
			})
		}).pipe(map(result => result.data[`keycloakUsersByNamePage`]));
	}

	findAll(): Observable<User[]> {
		return this.apollo.query({
			query: gql(`
        query keycloakUsers{
          keycloakUsers {
          		id,
				groups,
				createdTimestamp,
				username,
				enabled,
				emailVerified,
				firstName,
				lastName,
				email
          }
        }`)
		}).pipe(map(result => result.data[`keycloakUsers`]));
	}

// Former function with roles fecth nistead of groups
	// get(pagination: Pagination): Observable<Paginated<User>> {
	// 	return new Observable<Paginated<User>>(observer => {
	// 		const paginated = new Paginated<User>();
	// 		const url = 'http://keycloak.localhost/auth/admin/realms/pandora/users?' + new HttpParams({
	// 			fromObject: {
	// 				briefRepresentation: 'false',
	// 				first: pagination.offset.toString(),
	// 				max: pagination.first.toString()
	// 			}
	// 		}).toString();

	// 	const _count = this.http.get<number>('http://keycloak.localhost/auth/admin/realms/pandora/users/count');
	// 	const _users = this.http.get<User[]>(url);
	// 	const _clients = this.http.get<Client[]>('http://keycloak.localhost/auth/admin/realms/pandora/clients');
	//
	//
	// 	combineLatest(_count, _users, _clients, (count, users, clients) => ({count, users, clients}))
	// 		.subscribe(data => {
	// 				paginated.nodes = data.users;
	// 				paginated.take = data.users.length;
	// 				paginated.totalCount = data.count;
	// 				paginated.pageInfo = {
	// 					hasPreviousPage: (pagination.offset > pagination.first),
	// 					hasNextPage: (pagination.offset + pagination.first < data.count),
	// 					page: pagination.offset / pagination.first
	// 				};
	// 				let pandoraClient: Client;
	// 				let i = 0;
	// 				while (!pandoraClient) {
	// 					if (data.clients[i].clientId === 'pandora-api') {
	// 						pandoraClient = data.clients[i];
	// 					}
	// 					i++;
	// 				}
	// 				const observables = [];
	// 				let counter = 0;
	// 				paginated.nodes.forEach(user => {
	// 					const observableUser = this.http.get<Role[]>(' http://keycloak.localhost/auth/admin/realms/pandora/users/' + user.id +
	// 					'/role-mappings/clients/' + pandoraClient.id + '/composite');
	// 					observableUser.subscribe(
	// 						roles => {
	// 							user.roles = roles;
	// 							counter = counter + 1;
	// 							if (counter === paginated.nodes.length){ // vérifie si les roles de tous les user ont été ajoutés
	// 								observer.next(paginated);
	// 							}
	// 						}
	// 					);
	// 				});
	// 			}
	// 		);
	// 		}
	// 	);
	// }

	createUser(user: User): Observable<string> {
		return this.apollo.mutate({
			mutation: gql(`
        mutation createKeycloakUser($user: KeycloakUserInput!) {
         createKeycloakUser(user: $user)
        }`),
			variables: {
				user: plainToClass(User, user, {excludeExtraneousValues: true})
			}
		}).pipe(map(result => result.data[`createKeycloakUser`]));
	}

	sendPasswordEmailToUser(userId: string): Observable<void> {
		return this.apollo.mutate({
			mutation: gql(`
        mutation sendPasswordEmailToUser($userId: String!) {
         sendPasswordEmailToUser(userId: $userId)
        }`),
			variables: {
				userId
			}
		}).pipe(map(result => result.data[`sendPasswordEmailToUser`]));
	}

	updateUser(user: User): Observable<void> {
		return this.apollo.mutate({
			mutation: gql(`
        mutation updateKeycloakUser($user: KeycloakUserInput!) {
         updateKeycloakUser(user: $user)
        }`),
			variables: {
				user: plainToClass(User, user, {excludeExtraneousValues: true})
			}
		}).pipe(map(result => result.data[`updateKeycloakUser`]));
	}

	getUser(uuid?: string, username?: string): Observable<User> {
		return this.apollo.query({
			query: gql(`
        query keycloakUser($uuid: String, $username: String) {
         keycloakUser(uuid: $uuid, username: $username){
          		id,
				groups,
				createdTimestamp,
				username,
				enabled,
				emailVerified,
				firstName,
				lastName,
				email,
				attributes {customer, company}
          	}
        }`),
			variables: {
				uuid,
				username
			}
		}).pipe(map(result => result.data[`keycloakUser`]));
	}

	deleteUser(uuid: string): Observable<void> {
		return this.apollo.mutate({
			mutation: gql(`
        mutation deleteKeycloakUser($uuid: String!) {
         deleteKeycloakUser(uuid: $uuid)
        }`),
			variables: {
				uuid
			}
		}).pipe(map(result => result.data[`deleteKeycloakUser`]));
	}

	getGroups(): Observable<Group[]> {
		return this.apollo.query({
			query: gql(`
        query getKeycloakGroups {
         getKeycloakGroups {
         	id
			name
			path
         }
        }`)
		}).pipe(map(result => result.data[`getKeycloakGroups`]));
	}

	addGroupsUser(userId: string, groupId: string): Observable<void> {
		return this.apollo.mutate({
			mutation: gql(`
        mutation addKeycloakUserToGroup($userId: String!, $groupId: String!) {
         addKeycloakUserToGroup(userId: $userId, groupId: $groupId)
        }`),
			variables: {
				userId,
				groupId
			}
		}).pipe(map(result => result.data[`addKeycloakUserToGroup`]));
	}

	deleteGroupsUser(userId: string, groupId: string): Observable<void> {
		return this.apollo.mutate({
			mutation: gql(`
        mutation deleteKeycloakUserFromGroup($userId: String!, $groupId: String!) {
         deleteKeycloakUserFromGroup(userId: $userId, groupId: $groupId)
        }`),
			variables: {
				userId,
				groupId
			}
		}).pipe(map(result => result.data[`deleteKeycloakUserFromGroup`]));
	}

	whoAmI(): KeycloakProfile {
		return this.keycloakService.getKeycloakInstance().profile;
	}

	isAdmin(): boolean {
		return this.keycloakService.getKeycloakInstance().hasResourceRole(UserRolesEnum.ADMIN, 'pandora-api');
	}

	isCustomer(): boolean {
		return this.keycloakService.getKeycloakInstance().hasResourceRole(UserRolesEnum.CLIENT, 'pandora-api');
	}

	logout(): Promise<void> {
		return this.keycloakService.logout();
	}
}
