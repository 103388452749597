import { Expose, plainToClass, plainToInstance, Transform, Type } from 'class-transformer';
import {AbstractVersionFilter} from '../../shared/dto/generic/abstract-filter';
import {Paginated} from '../../shared/dto/generic/paginated';
import {VerisonedInput, Versioned} from '../../shared/dto/generic/versioned';
import {Asset} from '../asset/asset';
import {Room} from '../customer/room/room';
import {PreSale} from '../project/pre-sale/pre-sale';
import {Project} from '../project/project/project';
import {User} from '../user/user';
import {Schema, SchemaInput} from './schema/schema';

export class CreateSynopticResult {
	createdSynoptic: Synoptic;
	activeSynoptic: Synoptic;
	synoUuid: string;
}

export class Cables {
	[key: string]: number[];
}

@Expose()
export class CablesInput {
	[key: string]: number[];
}

export class Synoptic extends Versioned {
	room: Room;
	tags: string[];
	image: string;
	assets: Asset[];
	schemas: Schema[];
	preSale?: PreSale;
	project?: Project;
	cables: Cables;
	validatedBy: string;
	validatedAt: string;

	// view values
	validatedByUser: User;
	createdByUser: User;
	imageBlob: string;
	totalAssets: number;
	receivedPrct: number;
	installedPrct: number;
	testedPrct: number;

	static get viewValues(): string[] {
		return [
			'schemas',
			'assets',
			'validatedByUser',
			'createdByUser',
			'imageBlob',
			'totalAssets',
			'receivedPrct',
			'installedPrct',
			'testedPrct',
		];
	}
}

export class PaginatedSynoptic extends Paginated<Synoptic> {
}

export class SynopticInput extends VerisonedInput {
	@Expose() uuid: string;
	@Expose() version: number;
	@Expose() preSaleUuid?: string;
	@Expose() projectUuid?: string;
	@Expose() roomUuid: string;
	@Expose()
	@Transform((value) => {
		if (value.value !== null) {
			return JSON.stringify(value.value);
		}
		return value.value;
	})
	cables: string;
	@Expose() tags: string;
	@Expose() image: string;

	@Type(() => SchemaInput) @Expose() schemas: SchemaInput[];
}

// Ce qu'on peut filtrer

export class SynopticFilter extends AbstractVersionFilter {
	preSaleUuid?: string;
	projectUuid?: string;
	preSaleRef?: string;
	projectRef?: string;
	notInProjectOrPreSale?: boolean;
	customerWithRoomUuid?: string;
	inRoomInActiveProject?: string;
	customer?: string;
	roomUuid?: string;
	chipsFilter?: string[];
}
