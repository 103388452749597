<div class="paginator">
	<button (click)="paginate(pageIndex - 1)" [disabled]="pageIndex === 0" class="previous-btn">
		<span class="mr-2 icon-arrow_left"></span>
		<span>{{'paginator.previousPage' | transloco}}</span>
	</button>
	<div *ngFor="let page of pages" class="page-btn-container">
		<button (click)="paginate(page)" *ngIf="page !== -1" [disabled]="pageIndex === page" class="page-btn">
			{{(page + 1)}}
		</button>
		<div *ngIf="page === -1" class="ellipsis">...</div>
	</div>
	<button (click)="paginate(pageIndex + 1)" [disabled]="pageIndex === maxPageIndex() || maxPageIndex() === -1"
			class="next-btn">
		<span>{{'paginator.nextPage' | transloco}}</span>
		<span class="ml-2 icon-arrow_right"></span>
	</button>
	<input (ngModelChange)="onPageSizeChange($event)" *ngIf="displayPageSize" [matAutocomplete]="auto" [min]="1"
		   [ngModel]="pageSize" class="pagination page-btn" matInput
		   type="number">
	<mat-autocomplete #auto="matAutocomplete">
		<mat-option *ngFor="let rowCount of rowCounts" [value]="rowCount">{{rowCount}}</mat-option>
	</mat-autocomplete>
</div>
