import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarDismiss} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';
import {Observable} from 'rxjs';

export interface SnackbarOption {
	config?: MatSnackBarConfig;
	action?: string;
	doNotUseTranslateService?: boolean;
	translatorValues?: any;
}

/**
 * Snackbar service
 */
@Injectable({
	providedIn: 'root'
})
export class SnackbarService {

	/**
	 * Constructor
	 */
	constructor(private _snackbarService: MatSnackBar, private _translator: TranslocoService) {
	}

	/**
	 * Dismiss all snackbars
	 */
	dismissAll(): void {
		this._snackbarService.dismiss();
	}

	/**
	 * Show info snackbar
	 */
	showInfo(message: string, options?: SnackbarOption): Observable<MatSnackBarDismiss> {
		return this._showSnackbar('info', message, options);
	}

	/**
	 * Show warning snackbar
	 */
	showWarning(message: string, options?: SnackbarOption): Observable<MatSnackBarDismiss> {
		return this._showSnackbar('warning', message, options);
	}

	/**
	 * Show error snackbar
	 */
	showError(message: string, options?: SnackbarOption): Observable<MatSnackBarDismiss> {
		return this._showSnackbar('error', message, options);
	}

	/**
	 * Show success snackbar
	 */
	showSuccess(message: string, options?: SnackbarOption): Observable<MatSnackBarDismiss> {
		return this._showSnackbar('success', message, options);
	}

	/**
	 * Show snackbar of type with option
	 */
	private _showSnackbar(type: string, message: string, options: SnackbarOption): Observable<MatSnackBarDismiss> {
		if (!options) {
			options = new class implements SnackbarOption {
				config: MatSnackBarConfig = {};
				action: 'app.close';
				doNotUseTranslateService: boolean = false;
				translatorValues: null;
			}();
		}
		if (!options.doNotUseTranslateService) {
			message = this._translator.translate(message, options.translatorValues);
		}
		if (!options.config) {
			options.config = new MatSnackBarConfig<any>();
		}
		if (options.config.duration === null || options.config.duration === undefined) {
			options.config.duration = 3000;
		}
		options.config.panelClass = 'snack-' + type;
		return this._snackbarService.open(message, this._translator.translate(options.action), options.config).afterDismissed();
	}
}
