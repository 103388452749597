import {Injectable} from '@angular/core';
import {Utils} from '../../../shared/utils/utils';
import {Menu, Menus} from '../dto/menu';

@Injectable({
	providedIn: 'root'
})
export class MenuService {

	/**
	 * Menu list
	 */
	private _menus: Menus = [];

	constructor() {
	}

	/**
	 * GET
	 */
	get menus(): Menus {
		return this._menus;
	}

	/**
	 * Add menu entry to menu list
	 */
	public addMenuEntry(menus: Menus): void {
		if (menus) {
			for (const menu of menus) {
				if (this._menus.findIndex(value => value.label === menu.label) === -1) {
					this._menus.push(Object.assign(new Menu(), menu));
				}
			}
			this._menus.sort(Utils.comparing(item => item.position));
		}
	}

	/**
	 * Set "active" attribute on each menu entry depending on current route
	 */
	public refreshMenu(currentUrl?: string): void {
		if (currentUrl) {
			for (const menu of this._menus) {
				menu.active = menu.routes.some(value => currentUrl.startsWith(value)) || currentUrl === '/' && menu.isDefault;
			}
		}
	}
}
