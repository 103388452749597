import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Rack} from '../rack/rack';
import {Synoptic, SynopticInput} from '../synoptic/synoptic';

@Injectable({
	providedIn: 'root'
})
export class CopyService {

	constructor(public apollo: Apollo) {
	}

	createSynopticFromStateOfArt(roomUuid: string,
								 fields: (keyof Synoptic | string)[],
								 schemaName?: string,
								 preSaleUuid?: string,
								 projectUuid?: string): Observable<Synoptic> {
		return this.apollo.mutate<Synoptic>({
			mutation: gql(`
        mutation createSynoFromStateOfArt($roomUuid: String!, $schemaName: String, $preSaleUuid: String, $projectUuid: String) {
          createSynoFromStateOfArt(roomUuid: $roomUuid, schemaName: $schemaName, preSaleUuid: $preSaleUuid, projectUuid: $projectUuid){
           ${fields}
           }
        }`),
			variables: {
				roomUuid,
				schemaName,
				preSaleUuid,
				projectUuid
			}
		}).pipe(map(result => result.data[`createSynoFromStateOfArt`]));
	}

	copySynoptic(uuid: string, version: number, input: SynopticInput, fields: (keyof Synoptic | string)[]): Observable<Synoptic> {
		return this.apollo.mutate<Synoptic>({
			mutation: gql(`
        mutation copySynoptic($uuid: String!, $version: Int!, $input: SynopticInput!) {
          copySynoptic(uuid: $uuid, version: $version, input: $input){
           ${fields}
           }
        }`),
			variables: {
				uuid,
				version,
				input
			}
		}).pipe(map(result => result.data[`copySynoptic`]));
	}

	createNewVersionOfRack(rackUuid: string, rackVersion: number, projectUuid: string, fields: (keyof Rack | string)[]): Observable<Rack> {
		return this.apollo.mutate<Rack>({
			mutation: gql(`
        mutation createNewVersionOfRack($uuid: String!, $version: Int!, $projectUuid: String!) {
          createNewVersionOfRack(uuid: $uuid, version: $version, projectUuid: $projectUuid){
           ${fields}
           }
        }`),
			variables: {
				uuid: rackUuid,
				version: rackVersion,
				projectUuid
			}
		}).pipe(map(result => result.data[`createNewVersionOfRack`]));
	}
}
