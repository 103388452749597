import {Component} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {MenuService} from './layout/menu/service/menu.service';
import {environment} from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	env: 'dev' | 'preprod' | 'prod';
	version: string;

	constructor(private _menuService: MenuService,
				private _router: Router) {
		this.version = environment.version;
		if (window.location.href.includes('localhost') || window.location.href.includes('its-future.com')) {
			this.env = 'dev';
		} else if (window.location.href.includes('preprod.its-future.biz') || window.location.href.includes('preprod.deya.fr')) {
			this.env = 'preprod';
		} else {
			this.env = 'prod';
		}

		this._router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
			this._menuService.refreshMenu(event.url);
		});
	}
}
