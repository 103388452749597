import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router, UrlTree} from '@angular/router';

@Component({
	selector: 'app-back-button',
	templateUrl: './back-button.component.html',
	styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {

	@Input() goBackFn: () => void;

	@Input() paddingLeft: number = null;

	@Input() paddingRight: number = null;

	@Input() returnPlaceholder: string = 'app.back';

	constructor(private _route: ActivatedRoute, private _router: Router) {
	}

	goBack(): void {
		if (this._route.snapshot.queryParamMap.has('back')) {
			const urlTree: UrlTree = this._router.parseUrl(this._route.snapshot.queryParamMap.get('back'));
			this._router.navigate(urlTree.root.children.primary.segments.map(value => value.path), {queryParams: urlTree.queryParams});
		} else if (this.goBackFn) {
			this.goBackFn();
		} else {
			this._router.navigate(['..'], {
				relativeTo: this._route
			});
		}
	}
}
