import {Component, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-rack-add-accessory-dialog',
	templateUrl: './rack-add-accessory-dialog.component.html',
	styleUrls: ['./rack-add-accessory-dialog.component.scss']
})
export class RackAddAccessoryDialogComponent {

	form: UntypedFormGroup;

	constructor(private _fb: UntypedFormBuilder,
				private _dialogRef: MatDialogRef<RackAddAccessoryDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		this.form = this._fb.group({
			name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(255)]),
			size: new UntypedFormControl(1, [Validators.required, Validators.min(1), Validators.max(200)])
		});

		if (this.data) {
			this.form.patchValue(this.data);
		}
	}

	save(): void {
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}
		this._dialogRef.close(this.form.value);
	}

}
