import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {browserTracingIntegration, replayCanvasIntegration, replayIntegration} from '@sentry/angular-ivy';
import * as Sentry from '@sentry/angular-ivy';
import 'reflect-metadata';
import 'zone.js';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
	enableProdMode();
}

Sentry.init({
	enabled: environment.sentry.enabled,
	dsn: 'https://ad1eef30cc909acc2ea3939e4f7470d4@o4506631121797120.ingest.sentry.io/4506632350203904',
	integrations: [
		browserTracingIntegration(),
		replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
			maskAllInputs: false,
			networkDetailAllowUrls: ['/graphql']
		}),
		replayCanvasIntegration()
	],
	ignoreErrors: [
		// Random plugins/extensions
		'top.GLOBALS',
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		'originalCreateNotification',
		'canvas.contentDocument',
		'MyApp_RemoveAllHighlights',
		'http://tt.epicplay.com',
		'Can\'t find variable: ZiteReader',
		'jigsaw is not defined',
		'ComboSearch is not defined',
		'http://loading.retry.widdit.com/',
		'atomicFindClose',
		// Facebook borked
		'fb_xd_fragment',
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
		'bmi_SafeAddOnload',
		'EBCallBackMessageReceived',
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		'conduitPage',
		// Generic error code from errors outside the security sandbox
		// You can delete this if using raven.js > 1.0, which ignores these automatically.
		'Script error.',
		// Custom Angular
		'ExpressionChangedAfterItHasBeenCheckedError',
		'A drawer was already declared',
		'Unable to preventDefault inside passive event listener invocation',
		'no grant'
	],
	denyUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		// Custom App
		/api\/download\//i
	],
	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: environment.sentry.tracePropagationTargets,
	// Performance Monitoring
	tracesSampleRate: environment.sentry.tracesSampleRate, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	// This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
	// If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
});

Sentry.setTag('app-version', environment.version);
Sentry.setTag('app-env', environment.production ? 'production' : 'preproduction');

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.error(err));
