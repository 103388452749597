import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {ApolloClient} from '@apollo/client/core';
import {TranslocoService} from '@ngneat/transloco';
import * as Sentry from '@sentry/angular-ivy';
import {Apollo} from 'apollo-angular';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {KeycloakInitOptions} from 'keycloak-js';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {EnvironmentHttpLoader, EnvironmentRootModule} from './environment-root.module';
import {createApollo, GraphQLModule} from './graphql.module';
import {LayoutModule} from './layout/layout.module';
import {TestModule} from './page/test/test.module';
import {RoutingModule} from './routing.module';
import {InitializerProvider} from './shared/provider/initializer-provider';
import {errorInterceptorProvider} from './shared/service/interceptor/error.interceptor';
import {SharedService} from './shared/service/shared/shared.service';
import {SnackbarService} from './shared/service/snackbar/snackbar.service';
import {TranslocoRootModule} from './transloco-root.module';

/**
 * Register local data for date pipe
 */
registerLocaleData(localeFr, 'fr');

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		KeycloakAngularModule,
		RoutingModule,
		LayoutModule,
		HttpClientModule,
		TranslocoRootModule,
		EnvironmentRootModule,
		GraphQLModule,
		TestModule,
		// SynopticModule,
		// RackModule
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: (
				initializer: InitializerProvider,
				keycloakService: KeycloakService,
				environmentHttpLoader: EnvironmentHttpLoader,
				apollo: Apollo,
				sharedService: SharedService,
				snackbarService: SnackbarService): () => Promise<boolean> => (): Promise<boolean> => {
				return environmentHttpLoader.getConfig().then(async config => {
					environment.keycloak = config.keycloak;
					environment.url = config.url;
					environment.sentry = config.sentry;

					const initOptions: KeycloakInitOptions = {
						onLoad: 'check-sso',
						checkLoginIframe: false
					};

					await keycloakService.init({
						config: {
							realm: environment.keycloak.realm,
							clientId: environment.keycloak.clientId,
							url: environment.keycloak.url,
						},
						initOptions,
						loadUserProfileAtStartUp: true,
						enableBearerInterceptor: true,
						bearerExcludedUrls: ['/assets'],
					}).then(res => {
						if (res) {
							const scope: Sentry.Scope = Sentry.getCurrentScope();
							scope.setUser({
								id: keycloakService.getKeycloakInstance().subject,
								email: keycloakService.getUsername(),
								name: keycloakService.getKeycloakInstance().profile?.firstName + ' ' + keycloakService.getKeycloakInstance().profile?.lastName,
							});
						}
						return res;
					});


					apollo.client = new ApolloClient<any>(await createApollo(sharedService, keycloakService, snackbarService));

					return initializer.load();
				});
			},
			multi: true,
			deps: [InitializerProvider, KeycloakService, EnvironmentHttpLoader, Apollo, SharedService, SnackbarService]
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: environment.sentry.showDialog,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{
			provide: LOCALE_ID,
			useFactory: (translate: TranslocoService): string => translate.getActiveLang(),
			deps: [TranslocoService]
		},
		errorInterceptorProvider
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
