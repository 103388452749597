import {NgModule} from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {UserRolesEnum} from './objects/user/user-roles.enum';
import {RackComponent} from './page/rack/rack/rack.component';
import { SynopticPhotoComponent } from './page/synoptic/synoptic-photo/synoptic-photo.component';
import {AuthenticatedGuard} from './shared/guard/authenticated.guard';
import {RoleGuard} from './shared/guard/role.guard';

/**
 * All routes of the app
 */
const routes: Routes = [
	{
		path: 'synoptic-photo/:id/:version/:schemaId',
		component: SynopticPhotoComponent,
	},
	{
		path: 'rack-photo/:uuid/:version',
		component: RackComponent,
	},
	{
		path: '',
		redirectTo: '/projects',
		pathMatch: 'full'
	},
	{
		path: '',
		canActivate: [AuthenticatedGuard],
		canActivateChild: [AuthenticatedGuard],
		children: [
			{
				path: '',
				canActivate: [RoleGuard],
				data: {
					roles: [UserRolesEnum.ADMIN]
				},
				loadChildren: (): Promise<any> => import('./page/home/home.module')
					.then(m => m.HomeModule)
			},
			{
				path: 'admin',
				canActivate: [RoleGuard],
				data: {
					roles: [UserRolesEnum.ADMIN]
				},
				loadChildren: (): Promise<any> => import('./page/admin/admin-routing.module')
					.then(m => m.AdminRoutingModule)
			},
			{
				path: 'reporting',
				canActivate: [RoleGuard],
				data: {
					roles: [UserRolesEnum.ADMIN]
				},
				loadChildren: (): Promise<any> => import('./page/reporting/reporting-routing.module')
					.then(m => m.ReportingRoutingModule)
			},
			{
				path: 'articles',
				loadChildren: (): Promise<any> => import('./page/articles/articles-routing.module')
					.then(m => m.ArticlesRoutingModule)
			},
			{
				path: 'customers',
				loadChildren: (): Promise<any> => import('./page/customers/customers-routing.module')
					.then(m => m.CustomersRoutingModule)
			},
			{
				path: 'projects',
				loadChildren: (): Promise<any> => import('./page/project/project-routing.module')
					.then(m => m.ProjectRoutingModule)
			},
			{
				path: 'rack',
				loadChildren: (): Promise<any> => import('./page/rack/rack-routing.module')
					.then(m => m.RackRoutingModule)
			},
			{
				path: 'synoptic',
				loadChildren: (): Promise<any> => import('./page/synoptic/synoptic-routing.module')
					.then(m => m.SynopticRoutingModule)
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: false, preloadingStrategy: PreloadAllModules})],
	exports: [RouterModule]
})
export class RoutingModule {
}
