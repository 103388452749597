import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AbstractIdentifierService } from '../../shared/service/generic/abstract-identifier.service';
import { PortName, PortNameInput } from './port-name';

@Injectable({
	providedIn: 'root'
})
export class PortNameService extends AbstractIdentifierService<PortNameInput, PortName> {

	portNames: PortName[];

	constructor(public apollo: Apollo, public http: HttpClient) {
		super(apollo, 'PortName');
	}

	findAll(fields: (keyof PortName | string)[]): Observable<PortName[]> {
		if (!this.portNames) {
			return super.findAll(fields).pipe(tap(res => this.portNames = res));
		} else {
			return of(this.portNames);
		}
	}

	findAllRest(): Observable<PortName[]> {
		if (!this.portNames) {
			return this.http.get<PortName[]>(`${environment.url}/api/port-names`);
		}
		return of(this.portNames);
	}

	saveMultiple(input: PortNameInput[],
				 deleted: string[],
				 fields: (keyof PortName | string)[]): Observable<PortName[]> {
		return this.apollo.mutate<PortName>({
			mutation: gql(`
        mutation saveMultiplePortName($input: [PortNameInput], $deleted: [String]) {
          saveMultiplePortName(input: $input, deleted: $deleted) {
            ${fields}
          }
        }`),
			variables: {
				input,
				deleted
			}
		}).pipe(map(result => result.data[`saveMultiplePortName`]), tap(res => this.portNames = res));
	}
}
