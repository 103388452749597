import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Sort} from '../../dto/generic/sort';
import {Utils} from '../../utils/utils';

export abstract class BaseSpecificationsService<T, F> {

	constructor(protected apollo: Apollo, protected className: string) {
	}

	findOne(filter: F, fields?: (keyof T | string)[]): Observable<T> {
		return this.apollo.query({
			query: gql(`
        query find${this.className}($filter: ${this.className}Filter!) {
          find${this.className}(filter: $filter) {
            ${fields}
          }
        }`),
			variables: {
				filter
			}
		}).pipe(map(result => result.data[`find${this.className}`]));
	}

	filterAll(filter: F, fields: (keyof T | string)[], sort?: Sort): Observable<T[]> {
		return this.apollo.query({
			query: gql(`
        query ${Utils.lowerFirstChar(this.className)}s($sortColumn: String, $sortOrder: SortOrder, $filter: ${this.className}Filter) {
          ${Utils.lowerFirstChar(this.className)}s(sortColumn: $sortColumn, sortOrder: $sortOrder, filter: $filter) {
            ${fields}
          }
        }`),
			variables: {sortColumn: sort?.sortColumn, sortOrder: sort?.sortOrder, filter}
		}).pipe(map(result => {
			return result.data[`${Utils.lowerFirstChar(this.className)}s`];
		}));
	}
}
