import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Parameter, ParameterInput, ParameterMutationInput} from '../../dto/generic/parameter';
import {ParameterTypeEnum} from '../../enum/generic/parameter-type.enum';
import {AbstractIdentifierService} from './abstract-identifier.service';

@Injectable({
	providedIn: 'root'
})
export class ParameterService extends AbstractIdentifierService<ParameterInput, Parameter> {

	constructor(protected apollo: Apollo) {
		super(apollo, 'Parameter');
	}

	findAllByType(type: ParameterTypeEnum, fields: (keyof Parameter | string)[]): Observable<Parameter[]> {
		return this.apollo.query({
			query: gql(`
        query findParametersByType($type: ParameterTypeEnum!) {
          findParametersByType(type: $type) {
            ${fields}
          }
        }`),
			variables: {
				type
			}
		}).pipe(map(result => result.data[`findParametersByType`]));
	}

	findAllByTypes(types: ParameterTypeEnum[], fields: (keyof Parameter | string)[]): Observable<Parameter[]> {
		return this.apollo.query({
			query: gql(`
        query findParametersByTypes($types: [ParameterTypeEnum!]!) {
          findParametersByTypes(types: $types) {
            ${fields}
          }
        }`),
			variables: {
				types
			}
		}).pipe(map(result => result.data[`findParametersByTypes`]));
	}

	setModifiedOrders(input: ParameterMutationInput): Observable<number> {
		return this.apollo.mutate<number>({
			mutation: gql(`
        mutation setModifiedOrders($input: ParameterMutationInput!) {setModifiedOrders(input: $input)}`),
			variables: {
				input
			}
		}).pipe(map(result => result.data[`setModifiedOrders`]));
	}

}
