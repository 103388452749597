/**
 * Utils class for static functions
 */
import {isEqual, isObject, transform} from 'lodash';
import {AbstractIdentifier} from '../dto/generic/abstract-identifier';

export class Utils {

	/**
	 * Max file size in Mo
	 */
	static MAX_FILE_SIZE: number = 5;

	/**
	 * Compare two values
	 */
	static compare(a: number | string, b: number | string, isAsc: boolean = true): number {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	/**
	 * Sort helper
	 */
	static comparing(fct: any, isAsc: boolean = true): any {
		return (a, b) => this.compare(fct(a), fct(b), isAsc);
	}

	static compareAbstracIdentifier(a: AbstractIdentifier, b: AbstractIdentifier): boolean {
		return a && b ? a.uuid === b.uuid : a === b;
	}

	/**
	 * Permet de sort sur un seul champs et de rentrer dans un objet pour trier par un champs d'un sous objet
	 */
	static dynamicSort: (properties: string[], normalize?: boolean) => (a: any,
																		b: any) => number = (properties: string[],
																							 normalize?: boolean) => {
		let sortOrder: number = 1;
		// determine sort order by checking sign of last element of array
		if (properties[0].startsWith('-')) {
			sortOrder = -1;
			// Chop off sign
			properties[0] = properties[0].substr(1);
		}
		return (a, b): number => {
			let propertyOfA: any = Utils.recurseObjProp(a, properties);
			let propertyOfB: any = Utils.recurseObjProp(b, properties);
			if (normalize) {
				propertyOfA = Utils.normalize(propertyOfA);
				propertyOfB = Utils.normalize(propertyOfB);
			}
			const result: number = (propertyOfA < propertyOfB) ? -1 : (propertyOfA > propertyOfB) ? 1 : 0;
			return result * sortOrder;
		};
	};

	/**
	 * Takes an object and recurses down the tree to a target leaf and returns it value
	 * @param   root - Object to be traversed.
	 * @param   leafs - Array of downwards traversal. To access the value: {parent:{ child: 'value'}} -> ['parent','child']
	 * @param   index - Must not be set, since it is implicit.
	 * @return  The property, which is to be compared by sort.
	 */
	static recurseObjProp(root: object, leafs: string[], index: number = 0): any {
		const upper: object = root;
		// walk down one level
		const lower: object = upper[leafs[index]];
		// Check if last leaf has been hit by having gone one step too far.
		// If so, return result from last step.
		if (lower === null || lower === undefined) {
			return upper;
		}
		// Else: recurse!
		index++;
		// HINT: Bug was here, for not explicitly returning function
		// https://stackoverflow.com/a/17528613/3580261
		return Utils.recurseObjProp(lower, leafs, index);
	}

	/**
	 * Permet de faire du tri d'objet facilement en choisissant les champs de l'objet.
	 * En mettant un - devant le nom du champs on peut lui dire que c'est en ordre descendant sinon c'est ascendant par défaut
	 *
	 * Multi-sort your array by a set of properties
	 * @param args Arrays to access values in the form of: {parent:{ child: 'value'}} -> ['parent','child']
	 * @return Number - number for sort algorithm
	 */
	static dynamicMultiSort: (...args: string[]) => (a: any, b: any) => number = (...args: string[]) => {
		return (a, b): number => {
			let i: number = 0;
			let result: number = 0;
			const numberOfProperties: number = args.length;
			// REVIEW: slightly verbose; maybe no way around because of `.sort`-'s nature
			// Consider: `.forEach()`
			while (result === 0 && i < numberOfProperties) {
				result = Utils.dynamicSort(args[i].split('.'))(a, b);
				i++;
			}
			return result;
		};
	};

	static dynamicMultiSortNormalized: (...args: string[]) => (a: any, b: any) => number = (...args: string[]) => {
		return (a, b): number => {
			let i: number = 0;
			let result: number = 0;
			const numberOfProperties: number = args.length;
			// REVIEW: slightly verbose; maybe no way around because of `.sort`-'s nature
			// Consider: `.forEach()`
			while (result === 0 && i < numberOfProperties) {
				result = Utils.dynamicSort(args[i].split('.'), true)(a, b);
				i++;
			}
			return result;
		};
	};

	/**
	 * Track by identifier method for *ngFor
	 */
	static trackByIdentifier(index: number, item?: AbstractIdentifier): number | string {
		if (!item || !item.uuid) {
			return index;
		}
		return item.uuid;
	}

	/**
	 * Predicate de somme pour le calcul des totaux
	 */
	static sumValues: () => (a: any, b: any) => number = () => {
		return (a, b): number => {
			return a + b;
		};
	};

	/**
	 * Convert a base 64 image to a blob
	 * @param dataURI base64 string
	 * @param type of image to convert to
	 */
	static dataURItoFile(dataURI: string, filename: string, type: string = 'image/jpeg'): File {
		const byteString: string = window.atob(dataURI);
		const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
		for (let i: number = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		return new File([int8Array], filename, {type});
	}

	/**
	 * Deep diff between two object, using lodash
	 * https://gist.github.com/Yimiprod/7ee176597fef230d1451
	 *
	 * @param  object Object compared
	 * @param  base   Object to compare with
	 * @return        Return a new object who represent the diff
	 */
	static difference(object: any, base: any): any {
		const changes: (object: any, base: any) => any = (object, base) => {
			return transform(object, (result, value, key) => {
				if (!isEqual(value, base[key])) {
					result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
				}
			});
		};
		return changes(object, base);
	}

	/**
	 * Set first char to lower case
	 */
	static lowerFirstChar(value: string): string {
		return value.charAt(0).toLowerCase() + value.slice(1);
	}

	static loadPreview(file: File): Promise<string | ArrayBuffer> {
		return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
			const reader: FileReader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.onerror = error => reject(error);
		});
	}

	static addFile(
		accept: string = 'image/*',
		multiple: boolean = false,
		loadPreview: boolean = true,
		maxFileSizeCheck: number = Utils.MAX_FILE_SIZE):
		Promise<{file: File; image: string | ArrayBuffer | null, tooBig: boolean}[]> {
		return new Promise<{file: File, image: string | ArrayBuffer | null, tooBig: boolean}[]>((resolve, reject) => {
			const input: HTMLInputElement = document.createElement('input');
			input.type = 'file';
			input.multiple = multiple;
			input.accept = accept;
			input.click();
			input.addEventListener('change', (event: any) => {
				const promises: Promise<{file: File, image: string | ArrayBuffer | null, tooBig: boolean}>[] = [];
				if (!event.target.files) {
					return;
				}
				if (!multiple && event.target.files.length > 1) {
					console.warn('Only one file allowed, first one will be used !');
				}
				for (const file of event.target.files) {
					promises.push(new Promise((resolve1, reject1) => {
							const result: {file: File, image: string | ArrayBuffer | null, tooBig: boolean} = {
								file: null,
								image: null,
								tooBig: false,
							};
							result.file = file;
							if (maxFileSizeCheck && Utils.checkFileSizeTooBig(result.file.size)) {
								result.tooBig = true;
							}
							if (loadPreview && !result.tooBig) {
								const reader: FileReader = new FileReader();
								reader.readAsDataURL(file);
								reader.onload = () => {
									result.image = reader.result;
									if (event.parentNode) {
										event.parentNode.removeChild(this);
									}
									resolve1(result);
								};
								reader.onerror = error => reject1(error);
							} else {
								resolve1(result);
							}
						}),
					);
				}
				Promise.all(promises).then(value => {
					resolve(value);
				}).catch(err => {
					reject(err);
				});
			});
		});
	}

	static checkFileSizeTooBig(fileSize: number, maxFileSize: number = Utils.MAX_FILE_SIZE): boolean {
		return (fileSize / 1024 / 1024) > maxFileSize;
	}

	static insertStringAtPosition(mainString: string, pos: number, insertString: string): string {
		if (typeof (pos) === 'undefined') {
			pos = 0;
		}
		if (typeof (insertString) === 'undefined') {
			insertString = '';
		}
		return mainString.slice(0, pos) + insertString + mainString.slice(pos);
	}

	static removeAccent(text: string): string {
		return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	static normalize(text: string): string {
		if (!text) {
			return '';
		}
		return Utils.removeAccent(text.trim().toLowerCase());
	}

	static compressImage(src: string, newX: number, newY: number): Promise<string> {
		return new Promise((res, rej) => {
			const img: HTMLImageElement = new Image();
			img.src = src;
			img.onload = () => {
				const ratioWidth: number = newX / img.width;
				const ratioHeight: number = newY / img.height;
				const ratio: number = ratioWidth > ratioHeight ? ratioWidth : ratioHeight;

				const elem: HTMLCanvasElement = document.createElement('canvas');
				elem.width = img.width * ratio;
				elem.height = img.height * ratio;
				const ctx: CanvasRenderingContext2D = elem.getContext('2d');
				ctx.drawImage(img, 0, 0, img.width * ratio, img.height * ratio);
				const data: string = ctx.canvas.toDataURL();
				res(data);
			};
			img.onerror = error => rej(error);
		});
	}

	static flattenOnProperty<T>(array: T[], property: string): T[] {
		let arrayToReturn: T[] = [];
		for (const elem of array) {
			arrayToReturn.push(elem);
			if (Object.prototype.hasOwnProperty.call(elem, property)) {
				(elem[property] as T[]).forEach(e => e['parent'] = elem);
				arrayToReturn = arrayToReturn.concat(Utils.flattenOnProperty(elem[property], property));
			}
		}
		return arrayToReturn;
	}
}
