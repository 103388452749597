import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {VersioningSpecificationsService} from '../../shared/service/generic/versioning-specifications.service';
import {Schema, SchemaInput} from './schema/schema';
import {Synoptic, SynopticFilter, SynopticInput} from './synoptic';

@Injectable({
	providedIn: 'root'
})
export class SynopticService extends VersioningSpecificationsService<SynopticInput, Synoptic, SynopticFilter> {

	constructor(public apollo: Apollo, private http: HttpClient) {
		super(apollo, 'Synoptic');
	}

	updateSchemaName(schema: Schema): Observable<Schema> {
		return this.http.post<Schema>(environment.url + '/api/synoptic/schema/name', schema);
	}

	findSynosForStateOfArt(roomUuids: string[], fields: (keyof Synoptic | string)[]): Observable<Synoptic[]> {
		return this.apollo.query({
			query: gql(`
        query findSynosForStateOfArt($roomUuids: [String!]!) {
          findSynosForStateOfArt(roomUuids: $roomUuids) {
          ${fields}
          }
        }`),
			variables: {
				roomUuids
			}
		}).pipe(map(result => result.data[`findSynosForStateOfArt`]));
	}

	getSynoForPhoto(uuid: string, version: number | undefined): Observable<Synoptic> {
		return this.http.get<Synoptic>(`${environment.url}/api/synoptic/${uuid}/${version}/synoptic-photo`);
	}

	updateImage(uuid: string, version: number, path: string): Observable<string> {
		return this.apollo.mutate<string>({
			mutation: gql(`
        mutation updateImage($uuid: String!, $version: Int!, $path: String!) {
          updateImage(uuid: $uuid, version: $version, path: $path)
        }`),
			variables: {
				uuid,
				version,
				path
			}
		}).pipe(map(result => result.data[`updateImage`]));
	}

	validate(uuid: string, validation: boolean): Observable<string> {
		return this.apollo.mutate<string>({
			mutation: gql(`
        mutation validateSynoptic($uuid: String!, $validation: Boolean!) {
          validateSynoptic(uuid: $uuid, validation: $validation)
        }`),
			variables: {
				uuid,
				validation
			}
		}).pipe(map(result => result.data[`validateSynoptic`]));
	}

	addSchema(uuid: string, input: SchemaInput, fields: (keyof Schema | string)[]): Observable<Schema> {
		return this.apollo.mutate<Schema>({
			mutation: gql(`
        mutation addSchema($uuid: String!, $input: SchemaInput!) {
          addSchema(uuid: $uuid, input: $input){
          ${fields}
        	}
        }`),
			variables: {
				uuid,
				input
			}
		}).pipe(map(result => result.data[`addSchema`]));
	}

	patchCables(id: string, cables: string): Observable<Synoptic> {
		return this.http.patch<Synoptic>(`${environment.url}/api/synoptic/${id}`, {
			cables
		});
	}

	hasPreviousVersion(roomUuid: string): Observable<boolean> {
		return this.apollo.mutate<boolean>({
			mutation: gql(`
        query hasPreviousVersion($roomUuid: String!) {
          hasPreviousVersion(roomUuid: $roomUuid)
        }`),
			variables: {
				roomUuid
			}
		}).pipe(map(result => result.data[`hasPreviousVersion`]));
	}


}
