import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ConfirmDialogData {
	title?: string;
	content?: string;
	useTranslateForContent?: boolean;
	btnYes?: string;
	btnNo?: string;
	type?: 'error' | 'info' | 'success' | 'warning';
	showYesBtn?: boolean;
}

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

	/**
	 * Constructor
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
		if (!this.data) {
			this.data = new class implements ConfirmDialogData {
				btnNo: string;
				btnYes: string;
				content: string;
				useTranslateForContent: boolean;
				title: string;
				type: 'info' | 'error' | 'warning' | 'success';
				showYesBtn: boolean;
			}();
		}
		if (!this.data.title) {
			this.data.title = 'app.confirm';
		}
		if (this.data.useTranslateForContent === undefined || this.data.useTranslateForContent === null) {
			this.data.useTranslateForContent = true;
		}
		if (!this.data.content) {
			this.data.content = 'app.confirmDelete';
		}
		if (!this.data.btnYes) {
			this.data.btnYes = 'app.yes';
		}
		if (!this.data.btnNo) {
			this.data.btnNo = 'app.no';
		}
		if (!this.data.type) {
			this.data.type = 'warning';
		}
		if (this.data.showYesBtn === undefined || this.data.showYesBtn === null) {
			this.data.showYesBtn = true;
		}
	}
}
