import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractIdentifier, AbstractIdentifierInput} from '../../dto/generic/abstract-identifier';
import {Paginated} from '../../dto/generic/paginated';
import {Pagination} from '../../dto/generic/pagination';
import {Utils} from '../../utils/utils';
import {BaseService} from './base-service';

export abstract class AbstractIdentifierService<I extends AbstractIdentifierInput, T extends AbstractIdentifier> extends BaseService<T> {

	constructor(protected apollo: Apollo, protected className: string) {
		super(apollo, className);
	}

	get(uuid: string, fields?: (keyof T | string)[]): Observable<T> {
		return this.apollo.query({
			query: gql(`
        query ${Utils.lowerFirstChar(this.className)}($uuid: String!) {
          ${Utils.lowerFirstChar(this.className)}(uuid: $uuid) {
            ${fields}
          }
        }`),
			variables: {
				uuid
			}
		}).pipe(map(result => result.data[`${Utils.lowerFirstChar(this.className)}`]));
	}

	findByUuid(uuids: string[], fields: (keyof T | string)[]): Observable<T[]> {
		return this.apollo.query({
			query: gql(`
        query find${this.className}ByUuid($uuids: [String!]!){
          find${this.className}ByUuid(uuid: $uuids) {
            ${fields}
          }
        }`),
			variables: {
				uuids
			}
		}).pipe(map(result => result.data[`find${this.className}ByUuid`]));
	}

	findAllPaginated(pagination: Pagination, fields: (keyof T | string)[]): Observable<Paginated<T>> {
		return this.apollo.query({
			query: gql(`
        query paginated${this.className}s ($sortColumn: String, $sortOrder: SortOrder, $first: Int, $after: String, $offset: Int) {
          paginated${this.className}s(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after, offset: $offset) {
            totalCount,
            skip,
            take,
            pageInfo {
              hasPreviousPage,
              hasNextPage,
              startCursor,
              endCursor,
              page
            },
            nodes {
                ${fields}
            },
            edges {
              cursor,
              node {
          	  	${fields}
          	  }
            }
          }
        }`),
			variables: pagination
		}).pipe(map(result => result.data[`paginated${this.className}s`]));
	}

	create(input: I, fields: (keyof T | string)[]): Observable<T> {
		return this.apollo.mutate<T>({
			mutation: gql(`
        mutation create${this.className}($input: ${this.className}Input!) {
          create${this.className}(input: $input) {
            ${fields}
          }
        }`),
			variables: {
				input
			}
		}).pipe(map(result => result.data[`create${this.className}`]));
	}

	update(uuid: string, input: I, fields: (keyof T | string)[]): Observable<T> {
		return this.apollo.mutate<T>({
			mutation: gql(`
        mutation update${this.className}($uuid: String!, $input: ${this.className}Input!){
          update${this.className}(uuid: $uuid, input: $input) {
            ${fields}
          }
        }`),
			variables: {
				uuid,
				input
			}
		}).pipe(map(result => result.data[`update${this.className}`]));
	}

	deleteByUuid(uuid: string, fields: (keyof T | string)[]): Observable<T> {
		return this.apollo.mutate<T>({
			mutation: gql(`
        mutation delete${this.className}($uuid: String!){
          delete${this.className}(uuid: $uuid) {
            ${fields}
          }
        }`),
			variables: {
				uuid
			}
		}).pipe(map(result => result.data[`delete${this.className}`]));
	}
}
