<div class="header-bar">
	<div #backBtn class="header-back">
		<ng-content select="[back-button]"></ng-content>
	</div>

	<div [style.width]="'calc(100% - ' + ((backBtn.offsetWidth + headerBtn.offsetWidth) || 0) + 'px)'"
		 class="header-content">
		<ng-content></ng-content>
	</div>

	<div #headerBtn [style.padding-right.px]="btnPaddingRight" class="header-buttons">
		<ng-content select="[buttons]"></ng-content>
	</div>
</div>
