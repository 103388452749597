import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared/service/shared/shared.service';

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

	constructor(public sharedService: SharedService) {
	}

	ngOnInit(): void {
	}

}
