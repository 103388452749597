<div class="p-3">
	<div class="d-flex mb-4">
		<div class="mr-5">
			<h1>Titre de page</h1>
			<h2>Sous-titre</h2>
			<h3>Sous-menu Article</h3>
			<p>Texte normal</p>
			<div class="label mb-2">Label</div>
			<div class="light mb-2">Infos secondaires</div>
			<label>Libellé champ texte</label>
			<div class="small-label">label</div>
		</div>
		<div class="mr-4 mt-2">
			<div class="mb-2">
				<button class="color-btn big-btn">
					<span class="icon-clients_add mr-2"></span>
					<span>{{'customer.new' | transloco}}</span>
				</button>
			</div>
			<div class="mb-2">
				<button class="color-btn">
					{{'app.confirm' | transloco}}
				</button>
			</div>
			<div class="mb-2">
				<button class="text-btn">
					{{'app.cancel' | transloco}}
				</button>
			</div>
			<div class="mb-2">
				<button class="btn-icon">
					<span class="mr-2 icon-edit"></span>
				</button>
				<button class="btn-icon">
					<span class="mr-2 icon-delete"></span>
				</button>
				<button class="btn-icon">
					<span class="mr-2 icon-save"></span>
				</button>
			</div>
			<div class="mb-2">
				<button class="link-btn blue">
					<span class="mr-2 icon-back"></span>
					<span>{{'app.back' | transloco}}</span>
				</button>
			</div>
		</div>
		<div class="mr-4 mt-2">
			<div class="mb-2">
				<button class="color-btn big-btn" disabled>
					<span class="icon-clients_add mr-2"></span>
					<span>{{'customer.new' | transloco}}</span>
				</button>
			</div>
			<div class="mb-2">
				<button class="color-btn" disabled>
					{{'app.confirm' | transloco}}
				</button>
			</div>
			<div class="mb-2">
				<button class="text-btn" disabled>
					{{'app.cancel' | transloco}}
				</button>
			</div>
			<div class="mb-2">
				<button class="btn-icon" disabled>
					<span class="mr-2 icon-edit"></span>
				</button>
				<button class="btn-icon" disabled>
					<span class="mr-2 icon-delete"></span>
				</button>
				<button class="btn-icon" disabled>
					<span class="mr-2 icon-save"></span>
				</button>
			</div>
			<div class="mb-2">
				<button class="link-btn" disabled>
					<span class="mr-2 icon-back"></span>
					<span>{{'app.back' | transloco}}</span>
				</button>
			</div>
		</div>
	</div>

	<hr class="mb-4">

	<div class="d-inline-block align-top">
		<div class="d-inline-block mr-4">
			<div class="mb-2">
				<app-search></app-search>
			</div>
			<div class="mb-2">
				<mat-label>Label champ</mat-label>
				<mat-form-field floatLabel="always">
					<input [formControl]="input" matInput required>
					<mat-error>Texte d'erreur</mat-error>
				</mat-form-field>
			</div>
			<div class="mb-2">
				<mat-form-field floatLabel="always">
					<input [formControl]="input" matInput required>
					<mat-error>Texte d'erreur</mat-error>
				</mat-form-field>
			</div>
			<div class="mb-2">
				<mat-form-field floatLabel="always">
					<mat-label>Label champ</mat-label>
					<mat-select [formControl]="select" [placeholder]="'app.select' | transloco" required>
						<mat-option [value]="">-</mat-option>
						<mat-option [value]="'Option 1'">Option 1</mat-option>
						<mat-option [value]="'Option 2'">Option 2</mat-option>
					</mat-select>
					<mat-error>Texte d'erreur</mat-error>
				</mat-form-field>
			</div>
			<div class="mb-2">
				<mat-form-field>
					<mat-select [formControl]="select" [placeholder]="'app.select' | transloco" required>
						<mat-option [value]="">-</mat-option>
						<mat-option [value]="'Option 1'">Option 1</mat-option>
						<mat-option [value]="'Option 2'">Option 2</mat-option>
					</mat-select>
					<mat-error>Texte d'erreur</mat-error>
				</mat-form-field>
			</div>
			<div class="mb-2">
				<mat-checkbox [formControl]="checkbox">
					Checkbox
				</mat-checkbox>
			</div>
			<div class="mb-2">
				<mat-radio-group [formControl]="radio">
					<div>
						<mat-radio-button [value]="1">Radio opt 1</mat-radio-button>
					</div>
					<div>
						<mat-radio-button [value]="2">Radio opt 2</mat-radio-button>
					</div>
				</mat-radio-group>
			</div>

			<div class="mb-2">
				<mat-form-field class="input-date">
					<mat-label>Date picker</mat-label>
					<mat-datepicker-toggle [for]="picker2"></mat-datepicker-toggle>
					<input [matDatepicker]="picker2" matInput>
					<mat-datepicker #picker2></mat-datepicker>
				</mat-form-field>
			</div>
		</div>
		<div class="d-inline-block mr-5">
			<div class="mb-2">
				<app-search [disabled]="true"></app-search>
			</div>
			<div class="mb-2">
				<mat-form-field floatLabel="always">
					<mat-label>Label champ</mat-label>
					<input disabled matInput value="Champ rempli">
				</mat-form-field>
			</div>
			<div class="mb-2">
				<mat-form-field floatLabel="always">
					<input disabled matInput value="Champ rempli">
				</mat-form-field>
			</div>
			<div class="mb-2">
				<mat-form-field floatLabel="always">
					<mat-label>Label champ</mat-label>
					<mat-select [placeholder]="'app.select' | transloco" disabled value="Option 1">
						<mat-option value="">-</mat-option>
						<mat-option value="Option 1">Option 1</mat-option>
						<mat-option value="Option 2">Option 2</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="mb-2">
				<mat-form-field>
					<mat-select [placeholder]="'app.select' | transloco" disabled value="Option 1">
						<mat-option value="">-</mat-option>
						<mat-option value="Option 1">Option 1</mat-option>
						<mat-option value="Option 2">Option 2</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="mb-2">
				<mat-checkbox class="mr-2" disabled>
					Checkbox
				</mat-checkbox>
				<mat-checkbox [checked]="true" disabled>
					Checkbox
				</mat-checkbox>
			</div>
			<div class="mb-2">
				<mat-radio-group disabled value="1">
					<div>
						<mat-radio-button value="1">Radio opt 1</mat-radio-button>
					</div>
					<div>
						<mat-radio-button value="2">Radio opt 2</mat-radio-button>
					</div>
				</mat-radio-group>
			</div>
			<div class="mb-2">
				<mat-form-field class="input-date">
					<mat-label>Date picker</mat-label>
					<mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
					<input [matDatepicker]="picker" matInput>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
			</div>
		</div>
		<div class="d-inline-block mr-5 align-top">
			<div>
				<mat-form-field class="mr-2 input-number" floatLabel="always">
					<mat-label>{{'items.nb_values' | transloco}}</mat-label>
					<input [formControl]="number" autocomplete="off" matInput type="number">
				</mat-form-field>
				<mat-form-field class="input-number" floatLabel="always">
					<mat-label>{{'items.nb_values' | transloco}}</mat-label>
					<input disabled matInput type="number">
				</mat-form-field>
			</div>
			<div>
				<mat-slide-toggle class="mr-2"></mat-slide-toggle>
				<mat-slide-toggle class="mr-2" disabled></mat-slide-toggle>
				<mat-slide-toggle [checked]="true" class="mr-2" disabled></mat-slide-toggle>
			</div>
			<div class="mt-3">
				<mat-form-field class="status-toggle mr-3">
					<mat-select [placeholder]="'app.select' | transloco">
						<mat-option [value]="true">{{'app.stateEnum.ACTIVE' | transloco}}</mat-option>
						<mat-option [value]="false">{{'app.stateEnum.INACTIVE' | transloco}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div>
				<app-status-indicator [value]="'app.stateEnum.ACTIVE'" class="mr-3"></app-status-indicator>
				<app-status-indicator [value]="'app.stateEnum.INACTIVE'" class="mr-3"></app-status-indicator>
			</div>
			<div class="mt-3">
				<div>
					<app-todo-item>Article to-do list 1</app-todo-item>
				</div>
				<div>
					<app-todo-item [value]="true">Article to-do list 2</app-todo-item>
				</div>
				<div>
					<app-todo-item disabled>Article to-do list 3</app-todo-item>
				</div>
				<div>
					<app-todo-item [value]="true" disabled>Article to-do list 4</app-todo-item>
				</div>
			</div>
		</div>
	</div>

	<div class="d-inline-block">
		<h2>Pagination</h2>
		<ul>
			<li *ngFor="let item of paginatedItems">
				{{ item }}
			</li>
		</ul>
		<app-paginator (page)="paginate($event)"
					   [length]="testPaginationItems.length"
					   [pageIndex]="page"
					   [pageSize]="pageSize"></app-paginator>
	</div>

</div>
