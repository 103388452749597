import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';

@Component({
	selector: 'app-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
	/**
	 * Event emitted when the paginator changes the page size or page index
	 */
	@Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
	@Input() displayPageSize: boolean = false;
	maxDisplayedPages: number = 7;
	pages: number[] = [];

	pageTimeout: any;

	/**
	 * Number of elements per page
	 */
	private _rowCounts: number[] = [10, 20, 50, 100];

	get rowCounts(): number[] {
		return this._rowCounts;
	}

	@Input() set rowCounts(value: number[]) {
		this._rowCounts = value;
	}

	/**
	 * Number of elements per page
	 */
	private _pageSize: number = 10;

	get pageSize(): number {
		return this._pageSize;
	}

	@Input() set pageSize(value: number) {
		this._pageSize = value;

		this.definePageButtons();
		this.page.emit({
			pageSize: this._pageSize,
			pageIndex: this.pageIndex
		} as PageEvent);
	}

	/**
	 * Total number of elements
	 */
	private _length: number = 0;

	get length(): number {
		return this._length;
	}

	@Input() set length(value: number) {
		this._length = value;
		if (this.maxPageIndex() < this._pageIndex && this.maxPageIndex() !== -1) {
			this.paginate(this.maxPageIndex());
			return;
		}
		this.definePageButtons();
	}

	/**
	 * 0-based page index
	 */
	private _pageIndex: number = 0;

	get pageIndex(): number {
		return this._pageIndex;
	}

	@Input() set pageIndex(value: number) {
		this._pageIndex = value;
	}

	maxPageIndex(pageSize: number = this.pageSize): number {
		return Math.ceil(this._length / (pageSize || 1)) - 1;
	}

	ngOnInit(): void {
		this.definePageButtons();
	}

	paginate(page: number): void {
		this._pageIndex = page;
		this.page.emit({
			pageSize: this.pageSize,
			pageIndex: this._pageIndex
		} as PageEvent);
		this.definePageButtons();
	}

	definePageButtons(): void {
		this.pages = [];
		if (this.maxPageIndex() < this.maxDisplayedPages) {
			// Display all page buttons
			for (let i: number = 0; i <= this.maxPageIndex(); i++) {
				this.pages.push(i);
			}
		} else {
			// Display only some page buttons (always first, last & current, plus a few around current)
			const firstBreakPoint: number = Math.floor(this.maxDisplayedPages / 2);
			const lastBreakPoint: number = this.maxPageIndex() - Math.floor(this.maxDisplayedPages / 2);
			for (let i: number = 0; i <= this.maxPageIndex(); i++) {
				if (i === 0 || i === this.maxPageIndex() || Math.abs(i - this._pageIndex) <= 1) {
					this.pages.push(i);
				} else if ((this._pageIndex <= firstBreakPoint && i <= firstBreakPoint + 1)
					|| (this._pageIndex >= lastBreakPoint && i >= lastBreakPoint - 1)) {
					this.pages.push(i);
				} else if (this.pages[this.pages.length - 1] !== -1) {
					this.pages.push(-1);
				}
			}
		}
	}

	onPageSizeChange(pageSize: number): void {
		clearTimeout(this.pageTimeout);
		this.pageTimeout = setTimeout(() => {
			if (!pageSize) {
				pageSize = 10;
			}
			this._pageSize = pageSize;
			if (this.maxPageIndex(pageSize) < this.pageIndex) {
				this.pageIndex = 0;
			} else {
				this.pageSize = pageSize;
			}
		}, 400);
	}
}
