import {KeycloakService} from 'keycloak-angular';
import {User, UserCompany} from '../../../objects/user/user';
import {UserRolesEnum} from '../../../objects/user/user-roles.enum';

export class UserInfo {

	roles: string[] = [];

	isAdmin: boolean = false;

	isUser: boolean = false;

	isAdminOrUser: boolean = false;

	isCustomer: boolean = false;

	visibleCompanies: UserCompany[] = [];

	canSeeMultipleCompanies: boolean = false;

	user: User;

	userSub: string;

	constructor(public keycloakService: KeycloakService) {
		if (this.keycloakService?.getKeycloakInstance() && this.keycloakService.getKeycloakInstance().authenticated) {
			this.initData();
		}
	}

		initData(): void {
		if (this.keycloakService?.getKeycloakInstance()) {
			this.userSub = this.keycloakService.getKeycloakInstance().subject;
			this.user = this.keycloakService.getKeycloakInstance().profile as User;
			this.roles = this.keycloakService.getUserRoles(true);
			this.isAdmin = this.keycloakService.getKeycloakInstance().hasResourceRole(UserRolesEnum.ADMIN, 'pandora-api');
			this.isUser = this.keycloakService.getKeycloakInstance().hasResourceRole(UserRolesEnum.USER, 'pandora-api');
			this.isCustomer = this.keycloakService.getKeycloakInstance().hasResourceRole(UserRolesEnum.CLIENT, 'pandora-api');
			this.isAdminOrUser = !this.isCustomer;
			this.visibleCompanies = this.user.attributes?.company || [];
			this.canSeeMultipleCompanies = this.visibleCompanies.length > 1;
		}
	}
}
