import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';

@Component({
	selector: 'app-test',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

	input: UntypedFormControl;
	select: UntypedFormControl;
	checkbox: UntypedFormControl;
	radio: UntypedFormControl;
	number: UntypedFormControl;

	page: number = 0;
	pageSize: number = 3;
	testPaginationItems: string[] = [
		'A-un', 'A-deux', 'A-trois', 'A-quatre', 'A-cinq', 'A-six', 'A-sept', 'A-huit', 'A-neuf', 'A-dix', 'A-onze',
		'B-un', 'B-deux', 'B-trois', 'B-quatre', 'B-cinq', 'B-six', 'B-sept', 'B-huit', 'B-neuf', 'B-dix', 'B-onze',
		'C-un', 'C-deux', 'C-trois', 'C-quatre', 'C-cinq', 'C-six', 'C-sept', 'C-huit', 'C-neuf', 'C-dix', 'C-onze',
	];
	paginatedItems: string[] = this.testPaginationItems.slice(0, this.pageSize);

	constructor() {
	}

	ngOnInit(): void {
		this.input = new UntypedFormControl('', Validators.minLength(5));
		this.select = new UntypedFormControl('', Validators.required);
		this.checkbox = new UntypedFormControl('', Validators.required);
		this.radio = new UntypedFormControl('', Validators.required);
		this.number = new UntypedFormControl('', Validators.max(10));
	}

	paginate(event: PageEvent): void {
		this.page = event.pageIndex;
		this.pageSize = event.pageSize;
		this.paginatedItems = this.testPaginationItems.slice(this.page * this.pageSize, (this.page + 1) * this.pageSize);
	}
}
