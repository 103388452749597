import {Expose} from 'class-transformer';
import {AbstractIdentifier, AbstractIdentifierInput} from 'src/app/shared/dto/generic/abstract-identifier';

export class Schema extends AbstractIdentifier {
	viewId: string;
	name: string;
	json: string;
	parsedJson: any;
}

export class SchemaInput extends AbstractIdentifierInput {
	@Expose() uuid: string;
	@Expose() viewId: string;
	@Expose() name: string;
	@Expose() json: string;
}
