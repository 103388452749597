import {Type} from 'class-transformer';
import {AbstractUserDated, AbstractUserDatedInput} from './abstract-user-dated';

export class VersionInfo {
	version: number;

	versionCreatedAt: Date;
}

export abstract class Versioned extends AbstractUserDated {
	version: number;

	@Type(() => Date)
	versionCreatedAt: Date;

	versionList: VersionInfo[];

	lastVersion: boolean;

	maxVersion: boolean;
}

export abstract class VerisonedInput extends AbstractUserDatedInput {
}
