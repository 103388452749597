<div class="app-todo-item-container">
	<mat-checkbox (change)="value.checked = $event.checked;valueChange.emit(value)"
				  [checked]="value.checked"
				  [class.item-done]="value.checked"
				  [class]=" value.editing ? '':'is-not-editing'"
				  [disabled]="disabled"
				  class="todo-list-item">
		<div *ngIf="!value.editing">
			<ng-content></ng-content>
		</div>
	</mat-checkbox>

	<ng-container *ngIf="!disabled">
		<div [class.d-none]="!value.editing" class="input-container">
			<input #inputElem (keydown.enter)="value.editing = false; valueChange.emit(value)"
				   [(ngModel)]="value.value"
				   class="pr-2 pl-2"
				   type="text">
		</div>

		<button (click)="showItem(inputElem)" *ngIf="!value.editing" class="btn-icon todo-btn-icon icon-edit"
				type="button">
		</button>
		<button (click)="value.editing = false; removeTodo.emit();" *ngIf="value.editing"
				class="btn-icon icon-delete red"
				type="button">
		</button>
		<button (click)="value.editing = false; valueChange.emit(value)" *ngIf="value.editing"
				class="btn-icon icon-finish"
				type="button">
		</button>
	</ng-container>
</div>
