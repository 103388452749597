import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {CheckedListItem} from '../../../objects/project/project/project';

@Component({
	selector: 'app-todo-item',
	templateUrl: './checked-list-item.component.html',
	styleUrls: ['./checked-list-item.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CheckedListItemComponent implements AfterViewInit {

	@Input() disabled: boolean = false;
	@Input() value: CheckedListItem;
	@Output() valueChange: EventEmitter<CheckedListItem> = new EventEmitter<CheckedListItem>();
	@Output() removeTodo: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('inputElem') inputElem: ElementRef<HTMLInputElement>;

	constructor() {
	}

	ngAfterViewInit(): void {
		if (this.value.editing) {
			this.inputElem.nativeElement.focus();
		}
	}

	showItem(inputElem: HTMLInputElement): void {
		this.value.editing = true;
		setTimeout(() => {
			inputElem.focus();
		});
	}
}
