import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {AbstractIdentifierSpecificationsService} from '../../../shared/service/generic/abstract-identifier-specifications.service';
import {Site, SiteFilter, SiteInput} from './site';

@Injectable({
	providedIn: 'root'
})
export class SiteService extends AbstractIdentifierSpecificationsService<SiteInput, Site, SiteFilter> {
	constructor(public apollo: Apollo) {
		super(apollo, 'Site');
	}
}
