import {Expose} from 'class-transformer';

export enum UserCompany {
	DEYA = 'DEYA',
	VIDEOLINE = 'VIDEOLINE'
}

export class User {
	@Expose()
	id: string;
	@Expose()
	groups: string[];
	@Expose()
	createdTimestamp: number;
	@Expose()
	username: string;
	@Expose()
	enabled: boolean;
	@Expose()
	emailVerified: boolean;
	@Expose()
	firstName: string;
	@Expose()
	lastName: string;
	@Expose()
	email: string;
	@Expose()
	customer: string;
	@Expose()
	attributes: {
		customer: string[];
		company: UserCompany[];
	};
}

export class Group {
	@Expose()
	id: string;
	@Expose()
	name: string;
	@Expose()
	path: string;
}

export class Client {
	@Expose()
	id: string;
	@Expose()
	clientId: string;
}
