export abstract class AbstractFilter {
	uuid?: string;
	uuids?: string[];
	overrideNormalMode?: boolean;
}

export class VersionFilter {
	uuid: string;
	version: number;

	constructor(uuid: string, version: number) {
		this.uuid = uuid;
		this.version = version;
	}
}

export abstract class AbstractVersionFilter extends AbstractFilter {
	filterSubQueryToo?: boolean;
	elements?: VersionFilter[];
}
