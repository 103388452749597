<div mat-dialog-title>
	<h1 class="title-page">{{'rack.dialog.title' | transloco}}</h1>
	<button [mat-dialog-close]="null" class="icon-close"></button>
</div>
<form [formGroup]="form">
	<div mat-dialog-content>
		<p> {{'rack.dialog.giveInfos' | transloco}}</p>
		<mat-form-field class="d-block" floatLabel="always">
			<mat-label>{{'rack.dialog.site' | transloco}}</mat-label>
			<mat-select (selectionChange)="getRacksOfSite()" [placeholder]="'app.select' | transloco"
						formControlName="site">
				<mat-option *ngFor="let site of data.sites" [value]="site">
					{{site.name}}
				</mat-option>
			</mat-select>
			<mat-error *ngIf="form.get('site').hasError('required')">
				{{'form.required' | transloco}}
			</mat-error>
		</mat-form-field>

		<div *ngIf="form.get('site').value" class="row">
			<div class="col">
				<p class="mb-1">{{'rack.dialog.new' | transloco}}</p>
				<mat-form-field class="d-block" floatLabel="always">
					<mat-label>
						<span class="align-middle">{{'rack.dialog.name' | transloco}}</span>
					</mat-label>
					<input formControlName="name"
						   matInput
						   required
						   type="text">
					<mat-error *ngIf="form.get('name').hasError('required')">
						{{'form.required' | transloco}}
					</mat-error>
				</mat-form-field>
				<mat-form-field class="d-block" floatLabel="always">
					<mat-label>
						<span class="align-middle">{{'rack.size' | transloco}}</span>
					</mat-label>
					<input formControlName="size"
						   matInput
						   max="200"
						   min="1"
						   required
						   step="1"
						   type="number">
					<mat-error *ngIf="form.get('size').hasError('required')">
						{{'form.required' | transloco}}
					</mat-error>
					<mat-error *ngIf="form.get('size').hasError('min')">
						{{'form.min' | transloco: {min: form.get('size').getError('min').min} }}
					</mat-error>
					<mat-error *ngIf="form.get('size').hasError('max')">
						{{'form.max' | transloco: {max: form.get('size').getError('max').max} }}
					</mat-error>
				</mat-form-field>
			</div>

			<div *ngIf="data.project && racksOfSite?.length > 0" class="col">
				<p class="mb-1">{{'rack.dialog.takeOld' | transloco}}</p>
				<table *ngIf="racksOfSite.length > 0" [dataSource]="racksOfSite" class="rack-table" mat-table>
					<!-- Ref Column -->
					<ng-container matColumnDef="name">
						<th *matHeaderCellDef mat-header-cell>{{'rack.name' | transloco}}</th>
						<td *matCellDef="let element" class="ref-column" mat-cell>{{element.name}}</td>
					</ng-container>
					<!-- Id Column -->
					<ng-container matColumnDef="action">
						<th *matHeaderCellDef mat-header-cell></th>
						<td *matCellDef="let element" class="text-right" mat-cell>
							<button (click)="addRack(element)" class="color-btn" type="button">
								{{'rack.dialog.addToProject' | transloco}}
							</button>
						</td>
					</ng-container>

					<tr *matHeaderRowDef="displayedColumnsRack" mat-header-row></tr>
					<tr *matRowDef="let row; columns: displayedColumnsRack;" mat-row></tr>
				</table>
			</div>
		</div>
	</div>
</form>
<div mat-dialog-actions>
	<button [mat-dialog-close]="null" class="link-btn grey mr-3">
		{{'app.cancel' | transloco}}
	</button>

	<button (click)="save()" class="color-btn">
		{{'app.confirm' | transloco}}
	</button>
</div>
