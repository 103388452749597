import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {MenuService} from '../../layout/menu/service/menu.service';
import {User} from '../../objects/user/user';
import {UserRolesEnum} from '../../objects/user/user-roles.enum';
import {SnackbarService} from '../service/snackbar/snackbar.service';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard extends KeycloakAuthGuard  {
	constructor(
		protected router: Router,
		protected keycloakAngular: KeycloakService,
		private _menuService: MenuService,
		private _snackbar: SnackbarService
	) {
		super(router, keycloakAngular);
	}

	async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const requiredRoles: string[] = route.data.roles;
			const user: User = this.keycloakAngular.getKeycloakInstance().profile as User;

			if (!requiredRoles || requiredRoles.length === 0) {
				return resolve(true);
			} else {
				if (!this.roles || this.roles.length === 0) {
					resolve(false);
				}
				const hasAccess: boolean = requiredRoles.some(role => this.roles.indexOf(role) > -1);
				if (!hasAccess) {
					this._snackbar.showWarning('error.403');
					if (this.roles.includes(UserRolesEnum.CLIENT)) {
						this.router.navigate(['/customers', user.attributes?.customer[0]]);
						this._menuService.refreshMenu('/customers');
					} else {
						this.router.navigate(['/']);
					}
				}
				resolve(hasAccess);
			}
		});
	}
}
