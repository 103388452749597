import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {AbstractIdentifierSpecificationsService} from '../../../shared/service/generic/abstract-identifier-specifications.service';
import {Room, RoomFilter, RoomInput} from './room';

@Injectable({
	providedIn: 'root'
})
export class RoomService extends AbstractIdentifierSpecificationsService<RoomInput, Room, RoomFilter> {
	constructor(public apollo: Apollo, private http: HttpClient) {
		super(apollo, 'Room');
	}

	getRest(filter: RoomFilter): Promise<Room> {
		return firstValueFrom(this.http.post<Room>(`${environment.url}/api/rooms`, filter));
	}
}
