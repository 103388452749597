import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Article} from '../../../objects/article/article/article';
import {AbstractFilter} from '../../dto/generic/abstract-filter';
import {VerisonedInput, Versioned} from '../../dto/generic/versioned';
import {Utils} from '../../utils/utils';
import {AbstractIdentifierSpecificationsService} from './abstract-identifier-specifications.service';

export abstract class VersioningSpecificationsService<I extends VerisonedInput,
	T extends Versioned,
	F extends AbstractFilter>
	extends AbstractIdentifierSpecificationsService<I, T, F> {

	constructor(protected apollo: Apollo, protected className: string) {
		super(apollo, className);
	}

	findLastVersionNumber(uuid: string): Observable<T> {
		return this.apollo.query({
			query: gql(`
        query ${Utils.lowerFirstChar(this.className)}LastVersionNum($uuid: String!) {
          ${Utils.lowerFirstChar(this.className)}LastVersionNum(uuid: $uuid)
        }`),
			variables: {
				uuid
			}
		}).pipe(map(result => result.data[`${Utils.lowerFirstChar(this.className)}LastVersionNum`]));
	}

	getWithVersion(uuid: string, version: number, fields: (keyof T | string)[]): Observable<T> {
		return this.apollo.query({
			query: gql(`
        query get${this.className}WithVersion($uuid: String!, $version: Float!) {
          get${this.className}WithVersion(uuid: $uuid, version: $version) {
            ${fields}
          }
        }`),
			variables: {
				uuid,
				version
			}
		}).pipe(map(result => result.data[`get${this.className}WithVersion`]));
	}

	getAllVersions(uuid: string, fields: (keyof T | string)[]): Observable<T[]> {
		return this.apollo.query({
			query: gql(`
        query get${this.className}Versions($uuid: String!) {
          get${this.className}Versions(uuid: $uuid) {
            ${fields}
          }
        }`),
			variables: {
				uuid
			}
		}).pipe(map(result => result.data[`get${this.className}Versions`]));
	}

	deleteByUuidAndVersion(uuid: string, version: number, fields: (keyof T | string)[]): Observable<T> {
		return this.apollo.mutate<T>({
			mutation: gql(`
        mutation delete${this.className}WithVersion($uuid: String!, $version: Float!) {
          delete${this.className}WithVersion(uuid: $uuid, version: $version) {
            ${fields}
          }
        }`),
			variables: {
				uuid,
				version
			}
		}).pipe(map(result => result.data[`delete${this.className}WithVersion`]));
	}

	deleteAllVersionsByUuid(uuid: string, fields: (keyof T | string)[]): Observable<T[]> {
		return this.apollo.mutate<T[]>({
			mutation: gql(`
        mutation deleteAllVersionsOf${this.className}($uuid: String!){
          deleteAllVersionsOf${this.className}(uuid: $uuid) {
            ${fields}
          }
        }`),
			variables: {
				uuid
			}
		}).pipe(map(result => result.data[`deleteAllVersionsOf${this.className}`]));
	}
}
