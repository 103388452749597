import {CartoucheInfo, LitegraphComponent} from '@211-feelink/litegraph';
import {ArticleConfiguration} from '@211-feelink/litegraph/lib/litegraph';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {firstValueFrom, forkJoin, ObservableInputTuple} from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Asset } from '../../../objects/asset/asset';
import {AssetType, AssetTypeUtils} from '../../../objects/asset/asset-type.constants';
import { AssetService } from '../../../objects/asset/asset.service';
import { Customer } from '../../../objects/customer/customer';
import { RoomService } from '../../../objects/customer/room/room.service';
import { PortName } from '../../../objects/port/port-name';
import { PortNameService } from '../../../objects/port/port-name.service';
import { Schema } from '../../../objects/synoptic/schema/schema';
import { Synoptic } from '../../../objects/synoptic/synoptic';
import { SynopticService } from '../../../objects/synoptic/synoptic.service';
import { User, UserCompany } from '../../../objects/user/user';
import { SettingKey } from '../../../shared/enum/generic/setting-key.enum';
import {FileManagementService} from '../../../shared/service/file-management/file-management.service';
import { SettingService } from '../../../shared/service/generic/setting.service';
import {UserService} from '../../../shared/service/user/user.service';
import { Utils } from '../../../shared/utils/utils';

@Component({
	selector: 'app-synoptic-photo',
	templateUrl: './synoptic-photo.component.html',
	styleUrls: ['./synoptic-photo.component.scss'],
})
export class SynopticPhotoComponent implements OnInit {

	@ViewChild('litegraph', { static: false }) litegraph: LitegraphComponent;

	@ViewChild('litegraphContainer', { static: false }) litegraphContainer: ElementRef;

	synoptic: Synoptic;

	selectedSchema: Schema;

	pastedNodes: { nodes: any, assets: Asset[] };

	isProd: boolean = environment.production;

	printImage: string;
	printMode: boolean = false;

	portNames: Record<string, PortName> = {};
	assets: Asset[];

	customer: Customer;

	constructor(private route: ActivatedRoute,
				private synopticService: SynopticService,
				private portNameService: PortNameService,
				private userService: UserService,
				private assetService: AssetService,
				private roomService: RoomService,
				protected settingsService: SettingService,
				private fileManagementService: FileManagementService,
				private renderer: Renderer2) {
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			const uuid: string = params['id'];
			const version: number = +params['version'];
			const schemaId: string = params['schemaId'];
			if (uuid) {
				this.synopticService.getSynoForPhoto(uuid, version).pipe(first()).subscribe(async synoptic => {
					this.synoptic = synoptic;
					this.selectedSchema = this.synoptic.schemas.find(s => s.viewId === schemaId);

					const observables: ObservableInputTuple<any> = {
						ports: this.portNameService.findAllRest(),
						assets: this.assetService.filterAllRest({
							synopticUuid: this.synoptic.uuid,
							synopticVersion: this.synoptic.version,
							overrideNormalMode: true
						}),
						room: this.roomService.getRest({synoUuid: this.synoptic.uuid})
					};
					if (this.synoptic.creator) {
						observables.creator = this.userService.getUser(this.synoptic.creator);
					}
					if (this.synoptic.validatedBy) {
						observables.validatedBy = this.userService.getUser(this.synoptic.validatedBy);
					}
					await firstValueFrom(forkJoin(observables)).then(res => {
						res.ports.forEach(pn => this.portNames[pn.name] = pn);
						this.assets = res.assets;
						this.synoptic.room = res.room;
						this.customer = this.synoptic.room.zone.site.customer;
						this.synoptic.createdByUser = res.creator;
						this.synoptic.validatedByUser = res.validatedBy;
					});
					await this.litegraph.load();

					this._generateConfiguration();
					if (this.selectedSchema) {
						if (!this.selectedSchema.parsedJson && this.selectedSchema.json) {
							this.selectedSchema.parsedJson = this.selectedSchema.json;
						}
						this.litegraph.configureCables(this.synoptic.cables);
						this.litegraph.configureGraph(this.selectedSchema.parsedJson);
						await this._createCartoucheInfo();
						setTimeout(() => {
							this.preview();
						}, 1500);
					}
				});
			} else {
				console.error('No uuid provided');
			}
		});
	}

	preview(): void {
		if (this.selectedSchema) {
			this.printMode = true;
			this.litegraph.selectNodes([], false);
			const page: HTMLElement = this.litegraphContainer.nativeElement;
			this.renderer.setStyle(page, 'width', this.litegraph.A3_WIDTH * 1.75 + 'px');
			this.renderer.setStyle(page, 'height', this.litegraph.A3_HEIGHT * 1.75 + 'px');
			this.litegraph.fitCanvas();
			this.litegraph.stop();

			setTimeout(() => {
				const image: string = this.litegraph.getImage();
				this.printImage = image;
				const synoImage: any = image.replace('data:image/png;base64,', '');
				const synoName: string = `${this.synoptic.uuid}_${this.synoptic.version}_${this.selectedSchema.viewId}.png`;
				const synoBlob: Blob = Utils.dataURItoFile(synoImage, synoName, 'image/png');
				const blobUrl: any = URL.createObjectURL(synoBlob);
				const link: any = document.createElement('a'); // Or maybe get it from the current document
				link.href = blobUrl;
				link.download = synoName;
				link.innerHTML = ' ';
				link.className = 'synoLink';
				document.body.append(link);
				link.click();
			}, 300);
		}
	}

	addNodeType(config: ArticleConfiguration, translate: boolean = true): boolean {
		return this.litegraph.addNodeType(config);
	}

	protected _generateConfiguration(): void {
		if (!this.assets) {
			return;
		}
		this.assets.filter(asset => asset.article?.config && AssetTypeUtils.isArticle(asset.type)).forEach(asset => {
			this.addNodeType(asset.article.config);
		});
	}

	getNodeType: (nodeData: any) => Promise<ArticleConfiguration> = async (nodeData) => {
		if (nodeData.generic) {
			console.warn('Generic type not supported, should never come here');
			return null;
		}
		const assetOfType: Asset = this.pastedNodes.assets.find(value => value.article?.config?.type === nodeData.type);
		if (!assetOfType) {
			console.warn('type not found for asset', nodeData);
			return null;
		}

		return assetOfType.article.config;
	}

	protected async _createCartoucheInfo(): Promise<CartoucheInfo> {
		let owner: UserCompany;
		let key: SettingKey;
		if (this.synoptic.preSale || this.synoptic.project) {
			if (this.synoptic.preSale) {
				owner = this.synoptic.preSale.belongTo;
			} else {
				owner = this.synoptic.project.belongTo;
			}
		} else if (this.customer.belongTo?.length) {
			owner = this.customer.belongTo[0];
		}
		switch (owner) {
			case UserCompany.VIDEOLINE:
				key = SettingKey.VIDEOLINE_LOGO_IMAGE;
				break;
			case UserCompany.DEYA:
			default:
				key = SettingKey.DEYA_LOGO_IMAGE;
				break;
		}

		const cartoucheInfo: CartoucheInfo = {
			deyaLogo: this.settingsService.findByKey(key),
			customerLogo: this.customer?.imageUrl ? await this.fileManagementService.getFileBlob('customer', this.customer.imageUrl) : undefined,
			customerName: this.customer?.name,
			customerSite: this.synoptic.room.zone.site.name,
			customerRoom: this.synoptic.room.name,
			customerRoomPlace: this.synoptic.room.zone.name,
			synoSchema: this.selectedSchema?.name,
			synoVersion: this.synoptic.version.toString(),
			synoCreator: this._getUserName(this.synoptic.createdByUser),
			synoValidator: this._getUserName(this.synoptic.validatedByUser),
			synoVersionDate: new Date(this.synoptic.updatedAt).toLocaleDateString(),
			links: this._cableTypeList(),
		};
		this.litegraph.cartouche = cartoucheInfo;
		return cartoucheInfo;
	}

	private _cableTypeList(): string[] {
		const set: Set<string> = new Set<string>();
		if (!this.selectedSchema?.parsedJson?.links) {
			return [];
		}
		this.selectedSchema.parsedJson.links.forEach(value => {
			if (value) {
				set.add(value.outputType);
			}
		});

		return Array.from(set);
	}

	private _getUserName(user: User): string {
		let username: string = '';
		if (user?.firstName) {
			username += user.firstName.substring(0, 1).toUpperCase() + '. ';
		}
		if (user?.lastName) {
			username += user.lastName.toUpperCase();
		}
		return username;
	}
}
