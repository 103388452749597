import {Component, ViewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import { firstValueFrom } from 'rxjs';
import {environment} from '../../../../environments/environment';
import {UserInfo} from '../../../shared/component/user-info/user-info';
import {AppService} from '../../../shared/service/user/app.service';
import {UserService} from '../../../shared/service/user/user.service';
import {MenuService} from '../service/menu.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends UserInfo {

	@ViewChild('menuLauncher') menuLauncher: MatButton;
	version: string = environment.version;
	serverVersion: string;

	constructor(public menuService: MenuService,
				private _userService: UserService,
				private _appService: AppService,
				private _keycloakService: KeycloakService,
				private _router: Router) {
		super(_keycloakService);
		this.menuService.refreshMenu(this._router.url);
		firstValueFrom(this._appService.getAppVersion()).then(res => {
			this.serverVersion = res;
		});
	}

	openMenu(): void {
		this.menuLauncher._elementRef.nativeElement.click();
	}

	logout(): void {
		this._userService.logout().then();
	}

	goToMain(): void {
		if (this.isCustomer) {
			this._router.navigate(['customers', this.user.attributes.customer[0]]);
		} else {
			this._router.navigate(['/']);
		}
	}
}
