<app-header-bar>
	<app-back-button back-button></app-back-button>

	<div class="d-flex align-items-center h-100">
		<div class="d-flex align-items-center h-100">
			<button (click)="goToSynoptic()" *ngIf="synosInSameState.length"
					class="menu-switch-btn">{{'project.synoptics' | transloco}}</button>
			<button class="menu-switch-btn active">{{'project.racks' | transloco}}</button>
		</div>

		<div class="ml-auto mr-auto">
			<span class="mr-1">{{rack?.site?.customer?.name}}</span>
			<span class="mr-1"> > </span>
			<span class="mr-1">{{rack?.site?.name}}</span>
			<span class="mr-1"> > </span>
			<span class="mr-3 header-link-active">{{rack?.name}}</span>
		</div>

		<span class="state-chip">{{('rack.state.' + (rack?.project ? 'project' : 'stateOfArt')) | transloco}}</span>
	</div>

	<div buttons>
		<button (click)="checkAndUnlock()" *ngIf="readonly && isAdminOrUser && canUnlock"
				[matTooltip]="'app.unlock' | transloco"
				class="ml-2 btn-icon icon-edit">
		</button>
		<button (click)="lock()" *ngIf="!readonly && isAdminOrUser"
				[matTooltip]="'app.lock' | transloco"
				class="ml-2 btn-icon icon-lock">
		</button>
		<button (click)="delete()" *ngIf="!readonly && isAdmin"
				[matTooltip]="'app.delete' | transloco"
				class="ml-2 btn-icon icon-delete">
		</button>
		<button (click)="print()" [matTooltip]="'synoptic.download' | transloco"
				class="ml-2 btn-icon icon-download"></button>
		<button #saveBtn (click)="save(saveBtn)" *ngIf="!readonly"
				[matTooltip]="'app.save' | transloco"
				class="ml-2 btn-icon icon-save">
		</button>
	</div>
</app-header-bar>

<div class="menu-container">

	<div class="menu">
		<div *ngFor="let menu of menuContent; let index = index;" class="menu-content">
			<div (click)="openDrawer(index)" [class.active]="menu.active" [matTooltip]="menu.tooltip | transloco"
				 class="menu-entry">
				<div class="menu-entry-icon">
					<span class="icon-{{menu.icon}}"></span>
				</div>
			</div>
		</div>

		<div [class.menu-opened]="menuTrigger.menuOpen" class="bottom">
			<div (click)="menuTrigger.openMenu()" class="w-100 h-100">
				<div class="synos">
					<div class="square top-square"></div>
					<div class="square middle-square"></div>
					<div class="square bottom-square"></div>
					<div class="badge">{{racks.length}}</div>
					<div #menuTrigger="matMenuTrigger" [mat-menu-trigger-for]="afterMenu" class="menu-trigger"></div>
				</div>
			</div>
			<mat-menu #afterMenu="matMenu" [overlapTrigger]="true" xPosition="after" yPosition="above">
				<button (click)="goToRack(otherRack)"
						*ngFor="let otherRack of racks; let index = index"
						[class.active]="otherRack.uuid === rack.uuid"
						class="button-syno-sheet pl-0 align-items-center" mat-menu-item>
					<span>{{otherRack.name}}</span>
				</button>
				<button (click)="addRackSchema()" *ngIf="rack?.lastVersion" class="button-add-sheet pl-0" mat-menu-item>
					<span class="icon icon-add"></span>
					<span>{{'rack.menu.addRack' | transloco}}</span>
				</button>
			</mat-menu>
		</div>
	</div>
</div>

<div class="content-container">
	<mat-drawer-container [hasBackdrop]="false" class="h-100">
		<mat-drawer #drawer (closed)="closeDrawer()" class="drawer" mode="push">
			<form *ngIf="sideView === 0" [formGroup]="form">
				<h1>{{'rack.title' | transloco}}</h1>

				<mat-divider class="divider-full-form"></mat-divider>

				<h2>{{'rack.dimensions' | transloco}}</h2>
				<div class="d-flex">

					<mat-form-field class="d-block input-number mr-3" floatLabel="always">
						<mat-label>
							<span class="align-middle">{{'rack.size' | transloco}}</span>
						</mat-label>
						<input (ngModelChange)="onSizeChange()"
							   [(ngModel)]="rackSize"
							   [disabled]="readonly"
							   [ngModelOptions]="{standalone: true}"
							   matInput
							   max="200"
							   min="1"
							   required
							   step="1" type="number">
						<span class="ml-1" matSuffix>{{'unit.u.small' | transloco}}</span>
						<mat-error *ngIf="form.get('size').hasError('required')">
							{{'form.required' | transloco}}
						</mat-error>
						<mat-error *ngIf="form.get('size').hasError('min')">
							{{'form.min' | transloco: {min: form.get('size').getError('min').min} }}
						</mat-error>
						<mat-error *ngIf="form.get('size').hasError('max')">
							{{'form.max' | transloco: {max: form.get('size').getError('max').max} }}
						</mat-error>
					</mat-form-field>

					<mat-form-field class="d-block input-medium" floatLabel="always">
						<mat-label>
							<span class="align-middle">{{'rack.depth' | transloco}}</span>
						</mat-label>
						<input [matAutocomplete]="autoDepth"
							   formControlName="depth"
							   matInput
							   max="5000"
							   min="0"
							   step="50"
							   type="number">
						<span class="ml-1" matSuffix>{{'unit.cm.small' | transloco}}</span>
						<mat-autocomplete #autoDepth="matAutocomplete">
							<mat-option *ngFor="let parameter of parametersMap.get('RACK_DEPTH')" [value]="+parameter">
								{{parameter}}
							</mat-option>
						</mat-autocomplete>
						<mat-error *ngIf="form.get('depth').hasError('min')">
							{{'form.min' | transloco: {min: form.get('depth').getError('min').min} }}
						</mat-error>
						<mat-error *ngIf="form.get('depth').hasError('max')">
							{{'form.max' | transloco: {max: form.get('depth').getError('max').max} }}
						</mat-error>
					</mat-form-field>
				</div>
				<mat-divider class="divider-full-form"></mat-divider>
				<h2>{{'rack.appearance' | transloco}}</h2>

				<div class="d-flex align-items-center input-height">
					<mat-checkbox formControlName="bothSide">{{'rack.bothSide' | transloco}}</mat-checkbox>
				</div>

				<div class="d-flex align-items-center justify-content-between">
					<mat-form-field class="mr-3 input-medium flex-grow-1" floatLabel="always">
						<mat-label>{{'rack.structure' | transloco}}</mat-label>
						<mat-select [placeholder]="'app.select' | transloco"
									formControlName="structure">
							<mat-option *ngFor="let parameter of parametersMap.get('RACK_STRUCTURE_TYPE')"
										[value]="parameter">
								{{parameter}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="input-medium flex-grow-1" floatLabel="always">
						<mat-label>{{'rack.color' | transloco}}</mat-label>
						<input formControlName="color" matInput>
						<mat-error *ngIf="form.get('color').hasError('maxlength')">
							{{'form.maxlength' | transloco: {count: form.get('color').getError('maxlength').requiredLength} }}
						</mat-error>
					</mat-form-field>
				</div>

				<div class="d-flex align-items-center justify-content-between input-height">
					<mat-checkbox (change)="clearInput($event, 'frontDoor', 'frontLock')" formControlName="frontDoorCb">
						{{'rack.frontDoor' | transloco}}
					</mat-checkbox>
					<mat-form-field *ngIf="form.get('frontDoorCb').value" class="ml-3 input-medium">
						<mat-select formControlName="frontDoor">
							<mat-option *ngFor="let parameter of parametersMap.get('RACK_DOOR_TYPE')"
										[value]="parameter">
								{{parameter}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div *ngIf="form.get('frontDoorCb').value" class="d-flex align-items-center input-height">
					<mat-checkbox formControlName="frontLock">{{'rack.frontLock' | transloco}}</mat-checkbox>
				</div>

				<div class="d-flex align-items-center justify-content-between input-height">
					<mat-checkbox (change)="clearInput($event, 'backDoor', 'backLock')" formControlName="backDoorCb">
						{{'rack.backDoor' | transloco}}
					</mat-checkbox>
					<mat-form-field *ngIf="form.get('backDoorCb').value" class="ml-3 input-medium">
						<mat-select formControlName="backDoor">
							<mat-option *ngFor="let parameter of parametersMap.get('RACK_DOOR_TYPE')"
										[value]="parameter">
								{{parameter}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div *ngIf="form.get('backDoorCb').value" class="d-flex align-items-center input-height">
					<mat-checkbox formControlName="backLock">{{'rack.backLock' | transloco}}</mat-checkbox>
				</div>

				<div class="d-flex align-items-center justify-content-between input-height">
					<mat-checkbox (change)="clearInput($event, 'side')" formControlName="sideCb">
						{{'rack.side' | transloco}}
					</mat-checkbox>
					<mat-form-field *ngIf="form.get('sideCb').value" class="ml-3 input-medium">
						<mat-select formControlName="side">
							<mat-option *ngFor="let parameter of parametersMap.get('RACK_SIDE_TYPE')"
										[value]="parameter">
								{{parameter}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="d-flex align-items-center justify-content-between input-height">
					<mat-checkbox (change)="clearInput($event, 'feet')" formControlName="feetCb">
						{{'rack.feet' | transloco}}
					</mat-checkbox>
					<mat-form-field *ngIf="form.get('feetCb').value" class="ml-3 input-medium">
						<mat-select formControlName="feet">
							<mat-option *ngFor="let parameter of parametersMap.get('RACK_FEET_TYPE')"
										[value]="parameter">
								{{parameter}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</form>

			<div *ngIf="sideView === 1" [libRackgraphAssetZone]="rackgraph">
				<div class="px-3">
					<h1>{{'rack.menu.assets' | transloco}}</h1>

					<div *ngIf="!readonly" class="d-flex align-items-center input-height">
						<mat-checkbox
							(change)="showHideRacked($event)">{{'rack.hideRackedAssets' | transloco}}</mat-checkbox>
					</div>
				</div>

				<div *ngFor="let view of assetsView; trackBy: trackByRoomUuidFn">
					<button (click)="view.open = !view.open" class="left-menu-btn category-item category-btn">
						<div class="d-flex align-items-center flex-grow-1">
							<span *ngIf="view.articles?.length" [class.rotate-180]="view.open"
								  class="icon-arrow_down"></span>
							<span class="pl-2 text-truncate category-name">{{view.roomName}}</span>
							<span *ngIf="view.articles.length > 0"
								  class="count">({{view.articles.length}})</span>
						</div>
						<button (click)="$event.preventDefault(); addItemOfType(view)"
								*ngIf="view.roomUuid?.startsWith('RACK_') && !readonly"
								[matTooltip]="'rack.addAccessory' | transloco"
								class="btn-icon icon-add">
						</button>
					</button>
					<div [class.open]="view.open"
						 class="category-article-list">
						<div *ngFor="let article of view.articles; trackBy: trackByUuidFn"
							 [class.disabled]="article.asset.rackgraphId"
							 [draggable]="!article.asset.rackgraphId && !readonly"
							 [height]="article.height"
							 [libRackgraphAsset]="article.asset"
							 class="expansion-body">
							<div class="d-flex align-items-center">
								<div class="asset-count">
									<label>{{article.height}}{{'unit.u.small' | transloco}}</label>
								</div>
								<div class="asset-image">
									<img *ngIf="article.imageBlob" [alt]="'Image ' + article.name"
										 [src]="article.imageBlob | safizer: 'url'">
								</div>
								<span class="asset-name">{{article.name}}</span>
								<span *ngIf="article.asset.rackgraphId" class="light">
									({{'rack.placedInRack' | transloco}})
								</span>

								<button (click)="$event.preventDefault(); editItemOfType(view, article)"
										*ngIf="view.roomUuid?.startsWith('RACK_') && !article.asset.rackgraphId && !readonly"
										class="btn-icon icon-edit float-right">
								</button>
								<button (click)="$event.preventDefault(); removeItemOfType(view, article)"
										*ngIf="view.roomUuid?.startsWith('RACK_') && !article.asset.rackgraphId && !article.asset.shelfAssets?.length && !readonly"
										class="btn-icon icon-delete float-right">
								</button>
							</div>
							<ng-container *ngIf="article.asset?.shelfAssets">
								<div *ngFor="let shelfAsset of article.asset.shelfAssets"
									 class="d-flex my-2 align-items-center">
									<div class="asset-image">
										<img *ngIf="article.shelfAssetsImages.get(shelfAsset.uuid)"
											 [alt]="'Image ' + article.name"
											 [src]="article.shelfAssetsImages.get(shelfAsset.uuid) | safizer: 'url'">
									</div>
									<span class="asset-name">{{shelfAsset.name}}</span>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>

			<form *ngIf="sideView === 2" [formGroup]="form">
				<h1>{{'rack.menu.settings' | transloco}}</h1>
				<mat-form-field class="d-block" floatLabel="always">
					<mat-label>
						<span class="align-middle">{{'rack.name' | transloco}}</span>
					</mat-label>
					<input formControlName="name"
						   matInput
						   required
						   type="text">
					<mat-error *ngIf="form.get('name').hasError('required')">
						{{'form.required' | transloco}}
					</mat-error>
					<mat-error *ngIf="form.get('name').hasError('maxlength')">
						{{'form.maxlength' | transloco: {count: form.get('name').getError('maxlength').requiredLength} }}
					</mat-error>
				</mat-form-field>
				<mat-checkbox [(ngModel)]="showComments"
							  [ngModelOptions]="{standalone: true}">{{'rack.comments' | transloco}}</mat-checkbox>
			</form>
		</mat-drawer>

		<mat-drawer-content class="rack-content">
			<div class="rack-info-print">
				<table>
					<tr>
						<td>{{'rack.size' | transloco}}</td>
						<td>{{form.get('size').value}} {{'unit.u.small' | transloco}}</td>
						<td>{{'rack.depth' | transloco}}</td>
						<td>{{form.get('depth').value}} {{'unit.cm.small' | transloco}}</td>
					</tr>
					<tr>
						<td>{{'rack.bothSideSmall' | transloco}}</td>
						<td colspan="3">{{(form.get('bothSide').value ? 'app.yes' : 'app.no') | transloco}}</td>
					</tr>
					<tr>
						<td>{{'rack.structure' | transloco}}</td>
						<td>{{form.get('structure').value}}</td>
						<td>{{'rack.color' | transloco}}</td>
						<td>{{form.get('color').value}}</td>
					</tr>
					<tr>
						<td>{{'rack.frontDoor' | transloco}}</td>
						<ng-container *ngIf="!form.get('frontDoorCb').value">
							<td colspan="3">{{'app.no' | transloco}}</td>
						</ng-container>
						<ng-container *ngIf="form.get('frontDoorCb').value">
							<td>{{form.get('frontDoor').value}}</td>
							<td>{{'rack.frontLock' | transloco}}</td>
							<td>{{(form.get('frontLock').value ? 'app.yes' : 'app.no') | transloco}}</td>
						</ng-container>
					</tr>
					<tr>
						<td>{{'rack.backDoor' | transloco}}</td>
						<ng-container *ngIf="!form.get('backDoorCb').value">
							<td colspan="3">{{ 'app.no' | transloco}}</td>
						</ng-container>
						<ng-container *ngIf="form.get('backDoorCb').value">
							<td>{{form.get('backDoor').value}}</td>
							<td>{{'rack.backLock' | transloco}}</td>
							<td>{{(form.get('backLock').value ? 'app.yes' : 'app.no') | transloco}}</td>
						</ng-container>
					</tr>
					<tr>
						<td>{{'rack.side' | transloco}}</td>
						<td>{{form.get('side').value}}</td>
						<td>{{'rack.feet' | transloco}}</td>
						<td>{{form.get('feet').value}}</td>
					</tr>
				</table>
			</div>
			<div class="d-flex">
				<div *ngIf="rack" [class.without-comment]="!showComments" class="rack-container">
					<div class="rack-header">
						<div class="rack-info">
							{{'rack.definition' | transloco: {
							size: form.get('size').value,
							depth: form.get('depth').value,
							side: ('rack.front' | transloco)
						} }}
						</div>
						<div class="rack-comment">{{'rack.comment' | transloco}}</div>
					</div>
					<div class="rack-index-container">
						<div *ngFor="let number of rackSizeArray; let index = index"
							 [style.height.px]="rowHeight"
							 [style.line-height.px]="rowHeight"
							 class="rack-index">
							{{index + 1}}
						</div>
					</div>
					<div class="rack">
						<ng-container #rackFrontDiv></ng-container>
					</div>
				</div>
				<div *ngIf="rack" [class.d-none]="!form?.get('bothSide').value" [class.without-comment]="!showComments"
					 class="rack-container ml-3">
					<div class="rack-header">
						<div class="rack-info">
							{{'rack.definition' | transloco: {
							size: form.get('size').value,
							depth: form.get('depth').value,
							side: ('rack.back' | transloco)
						} }}
						</div>
						<div class="rack-comment">{{'rack.comment' | transloco}}</div>
					</div>
					<div class="rack-index-container">
						<div *ngFor="let number of rackSizeArray; let index = index"
							 [style.height.px]="rowHeight"
							 [style.line-height.px]="rowHeight"
							 class="rack-index">
							{{index + 1}}
						</div>
					</div>
					<div class="rack">
						<ng-container #rackBackDiv></ng-container>
					</div>
				</div>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>

	<lib-rackgraph (elementPlaced)="onElementPlaced($event)"
				   (elementRemoved)="onElementRemovedFromRack($event)"
				   *ngIf="rack"
				   [disabled]="readonly"
				   [elements]="assets"
				   [rackBackViewContainer]="rackBackVcr"
				   [rackFrontViewContainer]="rackFrontVcr"
				   [rackRowTemplate]="rackRowTpl"
				   [rackSize]="form?.get('size').value"
				   [removeElementFromRackFn]="removeElementFromRack"
				   [rowHeight]="rowHeight"
				   [showBackRack]="form?.get('bothSide').value">
		<ng-template #rackRowTpl let-back="back" let-index="index" let-row="row">
			<div class="rack-row">
				<ng-container *ngIf="!row.data">
					<div [style.line-height.px]="rowHeight - 2" class="rack-row-info"></div>
					<div [style.line-height.px]="rowHeight - 2" class="rack-row-comment"></div>
				</ng-container>

				<ng-container *ngIf="row.data && !row.data.shelfAssets?.length">
					<div [style.line-height.px]="rowHeight - 2" class="rack-row-info">
						<span>{{row.data.name}}</span>
					</div>
					<div [style.line-height.px]="rowHeight - 2" class="rack-row-comment">
						<textarea [(ngModel)]="row.data.comment" [disabled]="readonly"
								  (ngModelChange)="updateComment(row.data, index, !back)"></textarea>
					</div>
				</ng-container>

				<ng-container *ngIf="row.data?.shelfAssets?.length">
					<div [style.line-height.px]="rowHeight - 2" class="rack-row-info shelf">
						<div *ngFor="let asset of row.data.shelfAssets">
							{{asset.name}}
						</div>
					</div>
					<div [style.line-height.px]="rowHeight - 2" class="rack-row-comment shelf">
						<textarea *ngFor="let asset of row.data.shelfAssets" [(ngModel)]="asset.comment"
								  [disabled]="readonly"></textarea>
					</div>
				</ng-container>


				<button (click)="rackgraph.removeElementFromRack(row, index, back)"
						*ngIf="row.rackgraphId && !readonly" class="btn-icon icon-delete delete-btn"></button>
			</div>
		</ng-template>
	</lib-rackgraph>
</div>

<div *ngIf="sharedService.loaderVisible" class="loader-container">
	<div class="loader">
		<mat-spinner></mat-spinner>
	</div>
</div>

<div *ngIf="loaded" id="loaded"></div>
