import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lock } from './lock';

@Injectable({
	providedIn: 'root'
})
export class LockService {
	constructor(public apollo: Apollo) {
	}

	checkLock(type: string, uuid: string, version?: number): Observable<Lock> {
		return this.apollo.query({
			query: gql(`
        query checkLock($type: String!, $uuid: String!, $version: Int) {
          checkLock(type: $type, uuid: $uuid, version: $version) {
			  uuid,
			  creator,
			  createdAt,
			  type,
			  lockedUuid,
			  lockedVersion
          }
        }`),
			variables: {
				type,
				uuid,
				version
			}
		}).pipe(map(result => result.data['checkLock']));
	}

	lock(type: string, uuid: string, version?: number): Observable<Lock> {
		return this.apollo.mutate<Lock>({
			mutation: gql(`
        mutation lockElement($type: String!, $uuid: String!, $version: Int) {
          lockElement(type: $type, uuid: $uuid, version: $version) {
			  uuid,
			  creator,
			  createdAt,
			  type,
			  lockedUuid,
			  lockedVersion
          }
        }`),
			variables: {
				type,
				uuid,
				version
			}
		}).pipe(map(result => result.data['lockElement']));
	}

	unlock(type: string, uuid: string, version?: number, checkUser?: boolean): Observable<Lock> {
		return this.apollo.mutate<Lock>({
			mutation: gql(`
        mutation unlockElement($type: String!, $uuid: String!, $version: Int, $withUserCheck: Boolean) {
          unlockElement(type: $type, uuid: $uuid, version: $version, withUserCheck: $withUserCheck) {
			  uuid,
			  creator,
			  createdAt,
			  type,
			  lockedUuid,
			  lockedVersion
          }
        }`),
			variables: {
				type,
				uuid,
				version,
				withUserCheck: checkUser
			}
		}).pipe(map(result => result.data['unlockElement']));
	}

	findAll(): Observable<Lock[]> {
		return this.apollo.query<Lock[]>({
			query: gql(`
        query findAllLocks {
          findAllLocks {
			  uuid,
			  creator,
			  createdAt,
			  type,
			  lockedUuid,
			  lockedVersion
          }
        }`)
		}).pipe(map(result => result.data['findAllLocks']));
	}
}
