export enum AssetType {
	ARTICLE = 'ARTICLE',
	GENERIC = 'GENERIC',
	LINK = 'LINK',
	RACK_AVERAGE_PRICE = 'RACK_AVERAGE_PRICE',
	RACK_SHELF = 'RACK_SHELF',
	RACK_FILLER = 'RACK_FILLER',
	RACK_SOCKET = 'RACK_SOCKET'
}

export class AssetTypeUtils {
	static isNode = (assetType: AssetType): boolean => [AssetType.ARTICLE, AssetType.GENERIC].includes(assetType);
	static isArticle = (assetType: AssetType): boolean => AssetType.ARTICLE === assetType;
	static isGeneric = (assetType: AssetType): boolean => AssetType.GENERIC === assetType;
	static isLink = (assetType: AssetType): boolean => AssetType.LINK === assetType;
	static isSynoElement = (assetType: AssetType): boolean => [AssetType.ARTICLE, AssetType.GENERIC, AssetType.LINK].includes(assetType);
	static isRackElement = (assetType: AssetType): boolean => [AssetType.RACK_FILLER, AssetType.RACK_SOCKET, AssetType.RACK_SHELF, AssetType.RACK_AVERAGE_PRICE].includes(assetType);
	static isRackShelf = (assetType: AssetType): boolean => AssetType.RACK_SHELF === assetType;
	static isNotRackShelf = (assetType: AssetType): boolean => AssetType.RACK_SHELF === assetType;
}
