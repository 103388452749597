import {Expose, Type} from 'class-transformer';
import {AbstractFilter, VersionFilter} from '../../shared/dto/generic/abstract-filter';
import {AbstractIdentifier, AbstractIdentifierInput} from '../../shared/dto/generic/abstract-identifier';
import {AbstractUserDated, AbstractUserDatedInput} from '../../shared/dto/generic/abstract-user-dated';
import {Paginated} from '../../shared/dto/generic/paginated';
import {Article, ArticleField} from '../article/article/article';
import {PreSale} from '../project/pre-sale/pre-sale';
import {Project} from '../project/project/project';
import {Rack} from '../rack/rack';
import {Synoptic} from '../synoptic/synoptic';
import {AssetType} from './asset-type.constants';

export enum AssetInstallationStatus {
	RECEIVED = 'received',
	INSTALLED = 'installed',
	TESTED = 'tested'
}

export type AssetInstallationStatusType = {value: string, order: number, trad: string};
export class AssetInstallationStatusClass {
	RECEIVED: AssetInstallationStatusType = {
		value: AssetInstallationStatus.RECEIVED,
		order: 1,
		trad: 'project.asset.received.sing'
	};
	INSTALLED: AssetInstallationStatusType = {
		value: AssetInstallationStatus.INSTALLED,
		order: 2,
		trad: 'project.asset.installed.sing'
	};
	TESTED: AssetInstallationStatusType = {
		value: AssetInstallationStatus.TESTED,
		order: 3,
		trad: 'project.asset.tested.sing'
	};

	compareStatusLevel(a: AssetInstallationStatusType, b: AssetInstallationStatusType): number {
		return a.order > b.order ? -1 : 1;
	}
}

export class Asset extends AbstractUserDated {
	article: Article;
	synoptic: Synoptic;
	rack: Rack;
	schemaUuid: string;
	litegraphId: number;
	quantity: number;
	name: string;
	type: AssetType;
	comment: string;
	location: string;
	optional: boolean;
	existing: boolean;
	length: number;
	inputType: string;
	outputType: string;
	received: boolean;
	installed: boolean;
	tested: boolean;
	assetDetails?: AssetDetails[] = [new AssetDetails()];
	rackable: boolean;
	rackAccessoryHeight: number;
	shelf: Asset;
	shelfAssets: Asset[];
	price: number;
	rackgraphId: number;
	preSale?: PreSale;
	project?: Project;
	index?: number;
	// view values
	links: Asset[];
	input: boolean;
	linkedNodeName: string;
	status: AssetInstallationStatus | null;

	constructor(type: AssetType, article?: Article, schemaUuid?: string, litegraphId?: number) {
		super();
		this.type = type;
		if (article) {
			this.article = article;
			this.name = article.name;
		}
		if (schemaUuid) {
			this.schemaUuid = schemaUuid;
		}
		if (litegraphId) {
			this.litegraphId = litegraphId;
		}
	}
}

export class PaginatedAsset extends Paginated<Asset> {
}

export class AssetInput extends AbstractUserDatedInput {
	@Expose() uuid: string;
	@Expose() articleCategoryUuid: string;
	@Expose() siteUuid: string;
	@Expose() articleUuid: string;
	@Expose() articleVersion: number;
	@Expose() synopticUuid: string;
	@Expose() synopticVersion: number;
	@Expose() rackUuid: string;
	@Expose() rackVersion: number;
	@Expose() schemaUuid: string;
	@Expose() litegraphId: number;
	@Expose() quantity: number;
	@Expose() name: string;
	@Expose() type: AssetType;
	@Expose() comment: string;
	@Expose() location: string;
	@Expose() optional: boolean;
	@Expose() existing: boolean;
	@Expose() length: number;
	@Expose() inputType: string;
	@Expose() outputType: string;
	@Expose() received: boolean;
	@Expose() installed: boolean;
	@Expose() tested: boolean;
	@Type(() => AssetDetailsInput)
	@Expose() assetDetails: AssetDetailsInput[];
	@Expose() rackable: boolean;
	@Expose() rackAccessoryHeight: string;
	@Expose() shelfUuid: string;
	// @Expose() shelfAssets: string[];
	@Expose() price: string;
	@Expose() rackgraphId: number;
	@Expose() preSaleUuid?: string;
	@Expose() projectUuid?: string;
}

export class AssetFilter extends AbstractFilter {
	preSaleUuid?: string;
	projectUuid?: string;
	preSaleRef?: string;
	projectRef?: string;
	stateOfArt?: boolean;

	synopticUuid?: string;
	synopticVersion?: number;
	synoptics?: VersionFilter[];

	rackUuid?: string;
	rackVersion?: number;
	racks?: VersionFilter[];

	types?: AssetType[];

	sites?: string[];

	forRackSite?: string;
}

export class Shelf extends AbstractIdentifier {
	preSale?: PreSale;
	project?: Project;
	rackUuid: string;
	rackVersion: number;
	rackName: string;
	name: string;
	type: AssetType;
	comment: string;
	rackAccessoryHeight: number;
	shelfAssets: Asset[];
	price: number;
	rackgraphId: number;
}

export class MultipleAssetInput {
	addedAssets: AssetInput[];
	updatedAssets: AssetInput[];
	deletedAssets: string[];

	constructor() {
		this.addedAssets = [];
		this.updatedAssets = [];
		this.deletedAssets = [];
	}
}

export class AssetDetails extends AbstractIdentifier {
	asset: Asset;
	serialNumber?: string;
	guarantee?: Date;
}

export class AssetDetailsInput extends AbstractIdentifierInput {
	@Expose() uuid: string;
	@Expose() assetUuid: string;
	@Expose() serialNumber?: string;
	@Expose() guarantee?: Date;
}
