import {NgModule} from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from '../../layout/main-layout/main-layout.component';
import {Menus} from '../../layout/menu/dto/menu';
import {MenuService} from '../../layout/menu/service/menu.service';
import {SharedModule} from '../../shared/shared.module';
import {TestComponent} from './test.component';

const routes: Routes = [{
	path: 'test',
	component: MainLayoutComponent,
	children: [
		{
			path: '',
			component: TestComponent
		}
	]
}];

const menus: Menus = [{
	routes: ['/test'],
	label: 'Composants',
	position: 9,
	icon: 'admin'
}];

@NgModule({
	declarations: [TestComponent],
	imports: [
		SharedModule,
		RouterModule.forChild(routes),
		MatSelectModule,
		MatCheckboxModule,
		MatRadioModule,
		MatInputModule,
		MatSlideToggleModule,
	]
})
export class TestModule {
	constructor(private _menuService: MenuService) {
		// this._menuService.addMenuEntry(menus);
	}
}
