import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AppService {

	constructor(public http: HttpClient) {
	}

	getAppVersion(): Observable<string> {
		return this.http.get(environment.url + '/api/server-version', {responseType: 'text'});
	}
}
