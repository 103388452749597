import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {firstValueFrom} from 'rxjs';
import {UserInfo} from '../component/user-info/user-info';
import {SettingService} from '../service/generic/setting.service';

/**
 * Initializer provider
 */
@Injectable({
	providedIn: 'root'
})
export class InitializerProvider extends UserInfo {

	constructor(private _settingService: SettingService, private _keycloakService: KeycloakService) {
		super(_keycloakService);
	}

	/**
	 * Load important values on initialization
	 */
	async load(): Promise<boolean> {
		if (await this._keycloakService.isLoggedIn()) {
			this.initData();
			if (this.isAdminOrUser) {
				this._settingService.settings = await firstValueFrom(this._settingService.findAll(['uuid', 'key', 'value']));
			}
			return true;
		}
	}
}
