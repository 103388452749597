import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
	name: 'safizer'
})
export class SafizerPipe implements PipeTransform {

	constructor(private _sanitized: DomSanitizer) {
	}

	transform(value: any, args?: 'html' | 'style' | 'url' | 'resource' | 'script'): any {
		switch (args) {
			default:
			case 'html':
				return this._sanitized.bypassSecurityTrustHtml(value);
			case 'style':
				return this._sanitized.bypassSecurityTrustStyle(value);
			case 'url':
				return this._sanitized.bypassSecurityTrustUrl(value);
			case 'resource':
				return this._sanitized.bypassSecurityTrustResourceUrl(value);
			case 'script':
				return this._sanitized.bypassSecurityTrustScript(value);
		}
	}
}
