import packageJson from '../../package.json';

export const environment: any = {
	production: true,
	url: 'http://localhost:3000',
	keycloak: {
		realm: 'pandora',
		clientId: 'pandora-web',
		url: 'http://keycloak.localhost/auth/'
	},
	sentry: {
		enabled: true,
		showDialog: false,
		tracePropagationTargets: ['localhost', /^https:\/\/feelink\.deya\.fr\/api/],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	},
	version: packageJson.version // Version of the app
};
