		<div class="litegraph-container" #litegraphContainer *ngIf="!printImage">
			<lib-litegraph
				#litegraph
				[printMode]="printMode"
				[hideInfo]="isProd"
				[portNames]="portNames"
				[coloredHeaders]="true"
				[getNodeTypeFct]="getNodeType"
				[class.invisible]="!selectedSchema"
				[readonly]="true"
				[showCartridge]="true"></lib-litegraph>
		</div>
		<div class="print-image" *ngIf="printImage" id="printLoaded">
			<img [src]="printImage" alt="Schema">
		</div>
